import { Sourcify, UrlState, noDeps, HeliumRouteState, derive } from "helium-ui";

UrlState.preventRefreshWarningGiven = true;

export type RouteParamId = "sidebar";


export interface IRouteConfig {
	id: string;
	test: null | string | RegExp;
	paramId?: RouteParamId;
	getUrl?: string | (() => string);
	// icon?: IconType;
}


const RouteConfigs = {
	"presence": {
		test: "/presence"
	},
	"root": {
		test: /^\/?$/,
		getUrl: () => "/"
	},
	"portfolio": {
		test: "/portfolio",
	},
	"resumes": {
		test: "/resumes",
	},
	"blog": {
		test: "/blog"
	}
// } as Record<string, Omit<IRouteConfig, "id">>;
} as const;

export type RouteId = keyof typeof RouteConfigs;


export const UrlParamList = [
	"showFilter",
	"add",
	"less",
	"showContact",
	"printMode"
] as const;

export type UrlParam = typeof UrlParamList[number];
export type Stringable = string | number | boolean | Array<Stringable>

export class RouteState extends HeliumRouteState<RouteId, IRouteConfig, UrlParam> {
	constructor() {
		super(RouteConfigs);
		this.urlState.setConfig("defaultParamUpdateArgs", {
			keep: "all",
			noEncode: true,
			trueAsUnassigned: true,
	 	})
	}

	public getCurrentUrlWithParamsAs(params: Record<UrlParam, Stringable>) {
		const url = new URL(this.urlState.href);
		for (let key in params) {
			let value = params[key];
			if (Array.isArray(value)) {
				value = value.flat(50).join(",");
			}
			url.searchParams.set(key, String(value));
		}
		return url.href;
	}
}
