import { div, externalLink } from "helium-ui";
import { ILifeEvent, twoColLayout, extLink, fromMD } from "./LifeEvent";

export const TradeSkillEvents: ILifeEvent[] = [];

TradeSkillEvents.push({
  id: "TEFL",
  startDate: new Date("Apr 13 2024"),
  title: "TEFL (Teaching English as a Foreign Language)",
  images: ["other/tefl-logo.png", "other/tefl-cert.png"],
  skills: ["Featured ⭐️", "Task Delegation", "Civil Servant", "Peer Leadership"],
  shortDesc:
    "Online course in how to teach English while living in foreign countries.",
  // links: () => [
  // 	externalLink({
  // 		innards: `Free Stream/Download for "A Well Conditioned Lover"`,
  // 		href: "https://thumbz.bandcamp.com/album/a-well-conditioned-lover",
  // 	})
  // ],
  desc: () =>
    fromMD(`

	`),
});

TradeSkillEvents.push({
  id: "EMRCert",
  startDate: new Date("Jan 11 2022"),
  endDate: new Date("Jan 31 2022"),
  title: "Emergency Medical Responder Training",
  images: ["other/emr-cert.png"],
  skills: ["Featured ⭐️", "Volunteer", "Civil Servant"],
  shortDesc:
    "Keeping trauma patients alive long enough for paramedics to arrive;  blood, breath, breaks and (heart) beats.",
  // links: () => [
  // 	externalLink({
  // 		innards: `Free Stream/Download for "A Well Conditioned Lover"`,
  // 		href: "https://thumbz.bandcamp.com/album/a-well-conditioned-lover",
  // 	})
  // ],
  desc: () =>
    fromMD(`
In order *to become a better volunteer firefighter*, I took a three week class covering basic Emergency Medical Response.  Things we covered included:
- Cardiopulmonary Resuscitation (CPR)
- Ventilation and Opening Airways
- Oxygen Administration
- Patient Assessment and Interviewing
- Getting Vitals (Blood Pressure, Pulse / Ox, Etc)
- Wound Packing
- Tourniquets
- Patient Lifting and Moving Techniques
- Spinal Injury Stabilization
- Pregnancy and Child Birth

It was an awesome overall class which gave *a relatively in-depth and practical understanding of the human body*.
	`),
});

TradeSkillEvents.push({
  id: "WildlandFireCert",
  startDate: new Date("Oct 7 2021"),
  endDate: new Date("Oct 10 2021"),
  title: "Wildland Fire Fighting Cert (FF2)",
  images: ["/other/co-firecamp-logo.jpg", "/other/wildland-digging-line.jpg"],
  skills: ["Featured ⭐️", "Volunteer", "Civil Servant"],
  shortDesc: "A packed 40hr weekend course on wildfire mitigation and control.",
  // links: () => [
  // 	externalLink({
  // 		innards: `Free Stream/Download for "A Well Conditioned Lover"`,
  // 		href: "https://thumbz.bandcamp.com/album/a-well-conditioned-lover",
  // 	})
  // ],
  desc: () =>
    fromMD(`
When you feel like being *able to run towards the fire*, you've got to get red carded.  In particular, Fire Fighter 2 for wildfires.

**Allenspark Fire Protection District (AFPD)** sponsored my enrollment at **Colorado Fire Camp**, an 12-hours-a-day four-day class in South-Central Colorado.  It was *the first time I'd ever gone to camp!*

While there we covered everything required to be ready for a wildland fire, which included *the effects and relationships of fire, land, weather, and fuels*.  There was a surprising amount of weather education, with **"fire-weather"** being considered a single item and the central focus.

We also learned safety and fire-fighting techniques and *practiced with a live grass-fire operation*, some line-digging, bunker-deployment, hose-running, and "mop-up."  Bunker-deployment in particular was impactful: holding yourself inside a large air-less bag after a full sprint is an experience of self suffocation.  I never want to get caught in a burn-over... (shudder)

If you've ever wondered about wildland fire, I highly recommend this school!  It was a great education, and quite fun.
	`),
});

TradeSkillEvents.push({
  id: "WeldingCert",
  startDate: new Date("Jun 11 2016"),
  title: "Welding Certification",
  images: ["metalwork.gif"],
  skills: ["Featured ⭐️", "Fabrication"],
  shortDesc:
    "Safely making two pieces of metal into one; with various methods.",
  // links: () => [
  // 	externalLink({
  // 		innards: `Free Stream/Download for "A Well Conditioned Lover"`,
  // 		href: "https://thumbz.bandcamp.com/album/a-well-conditioned-lover",
  // 	})
  // ],
  desc: () =>
    fromMD(`
All of the projects imaged above were a result of a introductory welding class. In the class we learned how to do MIG and oxyacetylene welding, along with plasma cutting, pipe bending, sand blasting, and all the safety involved. I've since learned some blacksmithing and stick welding. My favorite welding style is oxyacetylene because it allows for small precision pieces.
	`),
});
