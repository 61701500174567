import {br, div, SingleInnard} from "helium-ui";

import "./webPresencePage.scss";

const IconSVG = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 521 339">
  <path id="Path"
        fill="none"
        vector-effect="non-scaling-stroke"
        d="M 11.00,11.00
           C 11.00,11.00 508.00,13.00 508.00,13.00
             508.00,13.00 507.00,327.00 507.00,327.00
             507.00,327.00 13.00,328.00 13.00,328.00
             13.00,328.00 11.00,11.00 11.00,11.00 Z
           M 10.00,30.00
           C 10.00,30.00 259.00,182.00 259.00,182.00
             259.00,182.00 507.00,30.00 507.00,30.00M 13.00,303.00
           C 13.00,303.00 206.00,149.00 206.00,149.00M 312.00,149.00
           C 312.00,149.00 506.00,303.00 506.00,303.00" />
</svg>
`;

const SoundCloudSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 100 50">
  <path id="Path"
    vector-effect="non-scaling-stroke"
        d="M 11.88,28.00
           C 11.88,28.00 11.94,39.94 11.94,39.94M 17.94,43.94
           C 17.94,43.94 17.88,23.94 17.88,23.94M 23.94,25.94
           C 23.94,25.94 23.81,43.94 23.81,43.94M 29.88,43.94
           C 29.88,43.94 29.88,17.94 29.88,17.94M 36.00,14.06
           C 36.00,14.06 35.88,44.00 35.88,44.00M 41.94,43.94
           C 41.94,43.94 41.88,16.00 41.88,16.00M 47.88,9.94
           C 47.88,9.94 47.81,43.88 47.81,43.88M 52.44,41.50
           C 52.44,41.50 52.38,10.38 52.38,10.38
             60.91,-1.45 83.18,10.27 79.69,22.25
             104.36,15.91 96.12,44.50 84.50,44.50
             84.50,44.50 55.38,44.50 55.38,44.50
             53.50,44.56 52.50,43.50 52.44,41.50 Z" />
</svg>
`;

const TwitterSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 44 39">
  <path id="Path"
    vector-effect="non-scaling-stroke"
        d="M 3.82,32.45
           C 11.55,31.55 14.00,29.00 13.91,28.91
             13.91,28.91 9.00,27.55 7.27,23.18
             7.27,23.18 10.18,23.09 10.18,23.09
             10.18,23.09 6.18,22.36 4.36,15.45
             4.36,15.45 7.36,16.09 7.36,16.09
             7.36,16.09 2.36,11.27 5.64,5.55
             7.82,9.18 17.00,13.45 22.00,13.18
             21.55,7.18 27.48,-0.22 36.64,6.18
             36.64,6.18 41.00,4.09 41.00,4.09
             41.00,4.09 38.64,7.91 38.64,7.91
             38.64,7.91 41.82,7.09 41.82,7.09
             41.82,7.09 38.36,10.73 38.36,10.73
             38.09,28.09 20.27,42.91 3.82,32.45 Z" />
</svg>
`;

const GithubSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 544 536">
  <path id="Path"
        vector-effect="non-scaling-stroke"
        d="M 335.33,500.33
           C 335.00,474.00 345.67,415.00 318.33,383.33
             443.67,375.33 460.33,249.33 408.33,187.67
             422.33,149.00 406.00,119.33 406.00,119.33
             406.00,119.33 373.00,118.00 336.00,146.00
             289.33,132.00 238.00,139.00 208.00,147.00
             172.00,120.67 137.33,119.33 137.33,119.33
             137.33,119.33 123.67,151.00 134.33,188.00
             77.00,260.00 115.67,380.67 224.67,383.33
             224.67,383.33 210.67,398.00 208.67,417.00
             208.67,417.00 187.00,432.67 154.67,418.00
             132.33,404.33 114.00,355.67 83.67,372.33
             78.67,377.67 95.67,383.67 110.00,401.33
             131.67,429.00 122.33,465.00 207.33,452.67
             207.33,452.67 208.67,501.33 208.67,501.33
             208.67,507.33 200.33,516.67 190.00,512.50
             -102.00,398.00 5.00,15.00 272.00,14.00
             572.00,17.00 619.00,428.00 353.00,511.00
             345.00,513.67 335.67,514.33 335.33,500.33 Z
           M 137.00,118.33" />
</svg>
`;

const YouTubeSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 540 380">
  <path id="Path"
        vector-effect="non-scaling-stroke"
        d="M 207.25,285.75
           C 207.25,285.75 207.00,94.25 207.00,94.25
             207.00,94.25 366.00,189.50 366.00,189.50
             366.00,189.50 207.25,285.75 207.25,285.75 Z
           M 425.50,363.50
           C 425.50,363.50 251.00,364.00 89.00,362.00
             10.33,360.00 14.33,245.67 14.33,196.67
             16.33,87.33 15.67,15.33 115.00,15.33
             193.75,15.12 251.00,14.00 429.50,17.00
             535.67,17.33 527.00,103.33 527.67,201.33
             525.67,322.67 512.50,363.00 425.50,363.50 Z" />
</svg>
`;

const GitLabSvg = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 84">
  <path id="Path"
        vector-effect="non-scaling-stroke"
        d="M 47.09,75.53
           C 47.09,75.53 86.00,46.18 86.00,46.18
             86.00,46.18 71.09,4.00 71.09,4.00
             71.09,4.00 47.09,75.53 47.09,75.53 Z
           M 22.36,4.55
           C 22.36,4.55 8.00,46.18 8.00,46.18
             8.00,46.18 47.09,75.53 47.09,75.53
             47.09,75.53 22.36,4.55 22.36,4.55 Z
           M 13.11,31.38
           C 13.11,31.38 80.73,31.42 80.73,31.42
             80.73,31.42 47.07,75.51 47.07,75.51
             47.07,75.51 13.11,31.38 13.11,31.38 Z" />
</svg>
`;

const StackOverflowSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 66 72">
  <path id="Unnamed"
        vector-effect="non-scaling-stroke"
        d="M 10.88,45.12
           C 10.88,45.12 10.83,61.91 10.83,61.91
             10.83,61.91 52.83,61.83 52.83,61.83
             52.83,61.83 52.88,45.12 52.88,45.12M 19.96,51.91
           C 19.96,51.91 43.70,51.87 43.70,51.87M 43.91,44.57
           C 43.91,44.57 21.61,40.39 21.61,40.39M 45.48,37.43
           C 45.48,37.43 25.17,27.78 25.17,27.78M 49.57,31.48
           C 49.57,31.48 32.26,16.65 32.30,16.65M 41.09,8.17
           C 41.09,8.17 54.65,26.57 54.65,26.57" />
</svg>
`;

const BandCampSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 224 124">
  <path id="Unnamed"
        vector-effect="non-scaling-stroke"
        d="M 66.00,23.50
           C 66.00,23.50 66.00,92.25 66.00,92.25
             66.00,92.25 66.00,59.75 66.00,59.75
             88.50,33.75 110.00,44.50 110.50,70.50
             110.50,98.50 82.25,104.25 66.50,79.00M 165.00,56.50
           C 162.25,37.00 124.25,40.50 124.25,71.00
             125.00,104.50 164.25,98.50 164.25,81.00M 7.75,111.25
           C 7.75,111.25 65.50,10.25 65.50,10.25
             65.50,10.25 214.50,11.00 214.50,11.00
             214.50,11.00 155.25,111.25 155.25,111.25
             155.25,111.25 7.75,111.25 7.75,111.25 Z" />
</svg>
`;

const PhoneSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 390 388">
  <path id="Unnamed"
        vector-effect="non-scaling-stroke"
        d="M 150.75,225.50
           C 127.00,196.00 100.75,160.00 136.00,133.00
             180.25,102.25 163.00,79.00 141.00,48.00
             114.50,5.75 103.75,3.50 63.00,39.00
             9.67,89.00 26.33,178.33 93.00,263.00
             169.33,354.33 251.50,401.00 336.00,342.00
             386.00,305.50 357.00,290.00 327.00,263.00
             291.50,232.50 276.00,234.00 253.00,256.00
             218.50,291.50 203.50,280.00 150.75,225.50 Z" />
</svg>
`;

const InstagramSvg = `
<svg xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 220 214">
  <path id="Unnamed"
        vector-effect="non-scaling-stroke"
        d="M 18.00,65.00
           C 17.60,41.12 36.25,20.75 61.50,20.00
             61.50,20.00 149.50,19.75 149.50,19.75
             180.77,19.98 192.75,36.75 193.50,62.50
             193.50,62.50 193.25,156.00 193.25,156.00
             193.25,174.50 178.00,192.75 150.00,193.00
             150.00,193.00 63.50,193.00 63.50,193.00
             35.75,192.75 19.50,176.50 18.75,152.50
             18.75,152.50 18.00,65.00 18.00,65.00 Z
           M 67.00,108.50
           C 67.00,54.50 147.50,51.00 148.00,108.50
             148.00,158.50 68.50,160.50 67.00,108.50 Z
           M 159.50,65.00
           C 146.25,65.25 143.25,44.00 159.50,44.00
             176.00,44.00 171.75,64.50 159.50,65.00 Z" />
</svg>
`;

const PatreonSvg = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 227.30849 242.80679">
  <path
    vector-effect="non-scaling-stroke"
      d="M 54.084883,230.72491 C 33.069797,228.3675 20.839375,207.50509 16.092657,188.81227 6.4994553,154.10371 5.3852484,117.21798 10.923406,81.744024 16.656727,54.282466 34.069043,27.485386 61.29656,17.571383 95.809419,3.2471839 135.88802,3.2066127 170.78956,16.169689 c 24.37348,10.608173 43.83522,35.925299 41.13006,63.382631 -1.79734,29.08661 -24.97938,55.1063 -53.68443,60.2355 -18.00565,4.51627 -37.69624,11.50841 -46.93701,28.99231 -12.323847,19.56027 -16.42317,46.0589 -37.850796,58.54883 -5.929121,3.01869 -12.764603,4.15277 -19.362501,3.39595 z"
      id="path1" />
</svg>
`


const SpotifySvg = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 83.395723 80.619383">
  <path
    vector-effect="non-scaling-stroke"
    d="M 37.582821,77.240889 C 19.943275,75.518115 4.9616177,58.993038 5.6696933,41.157531 5.4686904,23.305904 20.724022,6.854086 38.572322,5.8281635 50.421672,4.7618556 62.553,10.055011 69.819214,19.48309 79.940745,31.937967 79.951653,51.369752 69.687762,63.753823 62.443545,73.108671 50.357467,78.331308 38.57999,77.27311 38.247601,77.26237 37.915211,77.251629 37.582821,77.240889 Z M 57.607956,57.77732 c 0.902479,-3.429049 -2.821024,-5.395831 -5.557874,-6.140088 -4.402572,-1.32667 -8.810499,-2.737426 -13.465578,-2.713439 -5.198223,-0.230422 -10.423789,0.270372 -15.534003,1.136055 -4.6608,1.894597 0.627527,6.572118 3.661129,4.119829 9.461365,-1.958926 19.788952,-1.052658 28.237864,3.871611 0.844847,0.324063 1.966897,0.458109 2.658462,-0.273968 z m 3.36441,-9.054055 c 3.652025,-2.98351 -1.016322,-6.523825 -4.001922,-7.361203 -11.182172,-4.780589 -23.882211,-5.455608 -35.631505,-2.502711 -4.958643,2.749713 1.361999,7.993228 4.661421,4.744665 10.689186,-1.894993 22.250605,-0.912625 31.769965,4.609875 1.00174,0.429294 2.111317,0.688165 3.202041,0.509374 z m 4.91065,-11.135163 C 69.612189,35.150969 66.217571,30.925583 63.114299,29.952141 51.138498,24.841908 37.72997,23.6219 24.913026,25.639729 c -3.202003,0.289444 -9.481463,1.134456 -7.456959,5.842756 2.42994,4.041601 6.798455,0.577211 10.22959,0.532163 11.322723,-1.728077 23.19154,-0.143071 33.605235,4.61908 1.355665,0.776324 3.021914,1.806228 4.592124,0.954374 z"
      id="path1" />
</svg>
`

interface IConnection {
  icon: SingleInnard;
  medium: string;
  pathHref?: string;
  pathLabel: string;
}

const Connections: IConnection[] = [
  // {
  //   icon: {hackableHTML: IconSVG},
  //   medium: "Email",
  //   pathLabel: "Shady" + "Willow" + "Creek" + "@gmail.com",
  // },

  // {
  //   icon: {hackableHTML: PhoneSvg},
  //   medium: "Phone",
  //   pathLabel: "737-" + "529-" + "5031",
  // },

  

  {
    icon: {hackableHTML: PatreonSvg},
    medium: "Patreon (Travel Blog)",
    pathHref: "https://patreon.com/seph_reed",
    pathLabel: "patreon.com/seph_reed",
  },

  {
    icon: {hackableHTML: SpotifySvg},
    medium: "Spotify (2 Albums)",
    pathHref: "https://open.spotify.com/artist/6WgCquBSKvLdvuWiwNf0Ol",
    pathLabel: "open.spotify.com/artist/6WgCquBSKvLdvuWiwNf0Ol",
  },

  {
    icon: {hackableHTML: BandCampSvg},
    medium: "Bandcamp",
    pathHref: "https://thumbz.bandcamp.com/music",
    pathLabel: "thumbz.bandcamp.com/music",
  },

  {
    icon: {hackableHTML: StackOverflowSvg},
    medium: "StackOverflow",
    pathHref: "https://stackoverflow.com/users/story/4808079",
    pathLabel: "stackoverflow.com/users/story/4808079",
  },

  {
    icon: {hackableHTML: GitLabSvg},
    medium: "GitLab",
    pathHref: "https://gitlab.com/SephReed",
    pathLabel: "gitlab.com/SephReed",
  },

  {
    icon: {hackableHTML: GithubSvg},
    medium: "GitHub",
    pathHref: "https://github.com/SephReed",
    pathLabel: "github.com/SephReed",
  },
  
  {
    icon: {hackableHTML: InstagramSvg},
    medium: "Instagram",
    pathHref: "https://www.instagram.com/set_phreed/",
    pathLabel: "instagram.com/set_phreed",
  },

  {
    icon: {hackableHTML: SoundCloudSvg},
    medium: "SoundCloud",
    pathHref: "https://soundcloud.com/thumbz",
    pathLabel: "soundcloud.com/thumbz",
  },

  {
    icon: {hackableHTML: YouTubeSvg},
    medium: "YouTube",
    pathHref: "https://www.youtube.com/channel/UCo3sHpiACNnuw_32EZ8jMgQ/videos",
    pathLabel: "youtube.com/channel/UCo3sHpiACNnuw_32EZ8jMgQ",
  },

  {
    icon: {hackableHTML: TwitterSvg},
    medium: "Twitter",
    pathHref: "https://twitter.com/SephReed",
    pathLabel: "twitter.com/SephReed",
  },

  

]

export default function renderWebPresencePage() {

	return div("WebPresencePage", {id: "Contact"}, [
		// div("ContactTitle", "Web Presence"),
		br(4),
		div("Info", "(Here's some other places I host my stuff)"),
		div("Connections", Connections.map((conn) =>
      div("Connection", [
        conn.icon,
        div("Info", [
          div("Medium", conn.medium),
          conn.pathHref ? {hackableHTML: `<a class="Path" target="_blank" href="${conn.pathHref}">${conn.pathLabel}</a>`} : div("Path", conn.pathLabel),
        ]),
      ]),
		)),
	])
}
