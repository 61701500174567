import {div, externalLink} from "helium-ui";
import {ILifeEvent, twoColLayout, extLink, fromMD} from "./LifeEvent"


const NpmEvents: ILifeEvent[] = [];

/*********************
*   El-tool
**********************/
NpmEvents.push({
	id: "el-tool",
	startDate: new Date("Jun 1 2018"),
	// tags: ["featured", "work", "development"],
	title: "El-tool",
	skills: ["Vanilla JS", "Low-Level Web", "UX"],
	images: [
		"hobby-code/el-tool-example.png"
	],
	links: () => [
		externalLink({
			innards: "Npm package page",
			href: "https://www.npmjs.com/package/el-tool",
		}),
		externalLink({
			innards: "* The fastest/slowest ways to append elements to DOM",
			href: "https://howchoo.com/g/mmu0nguznjg/learn-the-slow-and-fast-way-to-append-elements-to-the-dom",
		}),
		externalLink({
			innards: "** Description of temporal/spacial locality",
			href: "https://stackoverflow.com/questions/7638932/what-is-locality-of-reference",
		})
	],
	shortDesc: "Syntactic sugar for creating HTMLElements, turned out to be extremely performant.",
	desc: () => fromMD(`
El-tool is the project which eventually flourished into \`helium-ui\`.  I started developing for Coder IDE code-base.  It *was basically just syntactic sugar to reduce the boilerplate for creating HTMLElements*... but then (on my birthday) I decided to do some benchmarks.  I compared and contrasted various ways (React, Vue, native) of generating a few thousand divs with random numbers as text and found that the only way to render a DOM faster was with raw (and non-interactive) HTML.  Basically, *I'd stumbled upon what was -- perhaps -- the fastest possible way to render an interactive DOM*.  Hard to believe, but here's the two key factors:

<ol><li>It's faster to use \`document.createElement("div")\` to create an element than it is to use html strings (React)
- this is true whether you create elements one at a time, or create an HTML string for thousands of divs
</li>
<li>Any loop or function that gets called a lot (in V8 engine) will become "hot"
- "hot" code is optimized for its common use, with look-ahead
- also, as a side effect of "temporal locality" (used by RAM/CPU recently), "hot" code has reduced fetch time
</li></ol>

Pretty neat!  But, much *like default React, El-Tool did not have any useful state management system*.  Because of that, I wouldn't consider it a true competitor to any widely used tools.  It wasn't *until it evolved into Helium* that it became something truly comparable.
	`),
});




NpmEvents.push({
	id: "lumas",
	startDate: new Date("July 1 2018"),
	title: "Lumas",
	images: ["Lumas-demo.gif"],
	skills: ["Procedural Gen.", "UX", "2d Art", "TypeScript"],
	links: () => [
		externalLink({
			innards: "Screencap of Lumas demo",
			href: "https://www.youtube.com/watch?v=47XC0WGpAQU",
		}),
		externalLink({
			innards: "* CIELAB color space",
			href: "https://en.wikipedia.org/wiki/CIELAB_color_space#CIELAB%E2%80%93CIEXYZ_conversions",
		}),
	],
	shortDesc: "A consistently readable UI pallette generator, meant to turn IDE themes into full site themes.",
	desc: () => fromMD(`
Lumas was a custom theming tool designed (while at Coder) to be able to *take arbitrary color palettes and turn them into readable, consistent UIs*.  It does this by mixing and matching color gradients and luminosity mappings.  The video below does a great job of showing these steps it takes when creating a theme:

1. First, organize the random colors of a set by luminosity (a function of human perception that takes some work to get right).
2. Then, make a gradient from black (0% luminosity) through the colors (in order of luminosity) to white (100% luminosity).
3. Finally, use luminosity mapped css variables such as foreground, background, faded text, and border color to specific luminosity positions within the gradient.

*By keeping luminosity constant, contrast remains stable* (regardless of changes to overall hue or saturation), and contrast is the most important part of any UI.  Calculating and setting luminosity is much more difficult than Value in HSV or Lightness in HSL color spaces.*
	`),
});


export default NpmEvents;
