import JobEvents from "./JobEvents";
import CodeEvents from "./CodeEvents";
import NpmEvents from "./NpmEvents";
import BuildEvents from "./BuildEvents";
import MusicEvents from "./MusicEvents";
import {ILifeEvent, Skill, SkillFilters} from "./LifeEvent";
import { FilterPresetId } from "src/state/EventFilterState";
import { TradeSkillEvents } from "./TradeSkillEvents";


const LifeEventList: ILifeEvent[] = [
	...JobEvents,
	...CodeEvents,
	...NpmEvents,
	...BuildEvents,
	...MusicEvents,
	...TradeSkillEvents
];

LifeEventList.sort((a, b) => {
	const aDate = (a.endDate || a.startDate);
	const bDate = (b.endDate || b.startDate);
	return aDate > bDate ? -1 : (aDate === bDate ? 0 : 1)
});


const skillUsed = new Map<Skill, boolean>();
Object.keys(SkillFilters).forEach((key: Skill) => skillUsed.set(key, false))

LifeEventList.forEach((ev) => {
	const presetMap = new Map<FilterPresetId, void>();
	ev.skills && ev.skills.forEach((skill) => {
		skillUsed.set(skill, true);
		SkillFilters[skill].forEach((pre) => presetMap.set(pre))
	})
	ev.presetMatches = Array.from(presetMap.keys());
})

const unusedSkills = Array.from(skillUsed.keys()).filter((skill) => skillUsed.get(skill) === false);
if (unusedSkills.length) {
	console.error(`These skills are not used by any life event: ${unusedSkills.join(", ")}`)
}

export default LifeEventList;
