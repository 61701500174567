import {  div, Innards, RawHTML } from "helium-ui";
// import * as MarkdownIt from "markdown-it";
import { FilterPresetId } from "src/state/EventFilterState";
// const MD = MarkdownIt({
// 	html: true,
// 	xhtmlOut: true,
// });
import snarkdown from "snarkdown";


export interface ILifeEvent {
	id: string;
	startDate: Date,
	endDate?: Date,
	// tags: Tags[];
	title: HTMLElement | string;
	desc: () => (Innards);
	shortDesc?: Innards;
	links?: () => Innards;
	images?: Array<string | { contain: string} | HTMLImageElement>;
	skills?: Skill[];
	presetMatches?: FilterPresetId[];
	hasExpletives?: boolean;
}

export function fromMD(str: string): RawHTML {
	// return {hackableHTML: MD.render(str)};
	const paragraphs = snarkdown(str).split("<br />").filter((it) => !!it);
	return { hackableHTML: paragraphs.map((text) => `<p>${text}</p>`).join("\n") };
}

export function extLink(innerText: string, href: string) {
	return `<a target="_blank" href=${href}>${innerText}</a>`;
}

export function twoColLayout(left: Innards, right: Innards) {
	return div("TwoColLayout", [
		div("LeftCol", Array.isArray(left) ? left : [left]),
		div("RightCol", Array.isArray(right) ? right : [right])
	]);
}




const satisfies = <T,>() => <U extends T>(u: U) => u;

export const SkillFilters = satisfies<Record<string, FilterPresetId[]>>()({
	"Featured ⭐️": ["featured"],
	"2d Art": ["art"],
	"3d Modeling": [],
	"Audio": [],
	"APIs": ["tech"],
	"Architecture": ["art"],
	"Bitwig": [],
	"Blender": ["art"],
	"C++": ["tech"],
	"Collaboration": ["leadership"],
	"Civil Servant": ["leadership"],
	"Documentation": ["tech"],
	"Event Systems": ["tech"],
	"Fabrication": ["art"],
	// "Feature Triage": ["leadership"],
	// "Git": ["tech"],
	"Games": ["tech"],
	"Grit": ["leadership"],
	"Helium UI": ["tech"],
	"HTML5 Canvas": ["tech"],
	"Interactive Art": ["art"],
	"Integrations": ["tech"],
	"Java": ["tech"],
	"Jazz Theory": ["art", "music"],
	"Laser Cutting": ["art", "tech"],
	"Linux": ["tech"],
	"Low-Level Web": ["tech"],
	"MapBox": ["tech"],
	"Mentorship": ["leadership"],
	"Mobile Apps": ["tech"],
	"Mix Mastering": ["art"],
	"Micro-services": ["tech"],
	"Micro-controllers": ["tech"],
	"NativeScript": ["tech"],
	"Onboarding": ["leadership"],
	"Node JS": ["tech"],
	// "NPM": ["tech"],
	"Peer Leadership": ["leadership"],
	"Procedural Gen.": ["tech", "art"],
	"Performance": ["art"],
	"Python": ["tech"],
	"React": ["tech"],
	"Roadmapping": ["leadership"],
	"RegEx": ["tech"],
	"Sass": ["tech"],
	// "Scoping": ["leadership"],
	"Servers": ["tech"],
	"SketchUp": [],
	"Sketch App": [],
	"Signal Processing": ["tech"],
	"Stakeholder Mgmt.": ["leadership"],
	// "Strategy": ["leadership"],
	"Stripe": ["tech"],
	"Structural Eng.": ["art"],
	"SQL": ["tech"],
	"Syntax Parsing": ["tech"],
	"Synths": ["music"],
	// "Team Advocating": ["leadership"],
	"Task Delegation": ["leadership"],
	"Three JS": ["tech"],
	"TypeScript": ["tech"],
	"UX": [],
	"Web App": ["tech"],
	"Website": [],
	"Web Workers": ["tech"],
	"Web Audio API": ["tech"],
	"Web Extensions": ["tech"],
	"Video": ["art"],
	"Volunteer": ["leadership"],
	"Vanilla JS": ["tech"]
});

export type Skill = keyof typeof SkillFilters;
