import "./header.scss";


import { addClass, append, div, el, img } from "helium-ui";
// import { StarsAnimation } from "../StarsAnimation/StarsAnimation";
import { TangentsAnimation } from "../TangentsAnimation/TangentsAnimation";
import { renderGlowingThumbzSymbol } from "../GlowingThumbzSymbol/GlowingThumbzSymbol";
import { StarAnimationScratch } from "../StarsAnimation/StarsAnimationScratch";
import { APP } from "src/state/AppState";




export function renderHeader() {
	if (APP.route.hasParam("printMode")) {
		return img("Header raster", {src: "/assets/img/raw/site/header-raster.png"});
	}
	return div("Header", {
		style: {
			height: (window.innerHeight * .6) + "px"
		}
	},[
		div("Divider"),
		div("MainArea", {
			ref: (mainAreaEl) => {
				append(mainAreaEl, [
					div("Foreground", [
						div("Name hideUntilFontsLoaded", [
							"SEPH ",
							renderGlowingThumbzSymbol(),
							" REED",
						])
					]),
					div("Background", [
						// () => {
						// 	const stars = new StarsAnimation({
						// 		mousemoveTarget: mainAreaEl
						// 	});
						// 	stars.play();
						// 	return stars.domNode;
						// },
						div("Fog"),
						() => {
							const stars = new StarAnimationScratch({
								mousemoveTarget: mainAreaEl
							});
							stars.updateSize();
							stars.play();
							return addClass("StarsAnimation", stars.canvas);
						},
						() => {
							const out = el("canvas", { class: "Tangents"});
							const tangents = new TangentsAnimation({
								canvas: out,
								mousemoveTarget: mainAreaEl
							});
							return out;
						},
						div("Mountains", { id: "Mountains" }),
						div("Treeline"),
						div("NearMountain"),
					]),
				])
			}
		}),
		div("Divider reverse"),
	])
}
