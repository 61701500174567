import "./eventFilter.scss";

import { anchor, br, byId, div } from "helium-ui";
import { FilterPresetIdList } from "src/state/EventFilterState";
import { APP } from "src/state/AppState";
import { tweener } from "helium-ui-tweener";
import { Skill, SkillFilters } from "src/LifeEventList/LifeEvent";
import { renderSliderToggle } from "helium-ui-components";
// import {} from "he"



export function renderEventFilter() {

	return div("EventFilter", [
		br(4),
		div("Info", "Which kind of achievements are you interested in?"),
		div("Instructions", "(select a filter and scroll down)"),
		br(),
		div("FilterButtons", FilterPresetIdList.map((id) => {
			if (id === "custom") { return; }

			return anchor("FilterButton " + id, {
				// style: { backgroundImage: `url("${imgSrc}")`},
				innards: div("Name", () => {
					switch (id) {
						case "featured": return "Featured";
						case "all": return "Everything";
						case "art": return "Art & Fab";
						case "tech": return "Tech";
						case "music": return "Music";
						case "leadership": return "Leadership";
					}
				}),
				href: () => ({
					path: APP.route.getUrlForRoute("portfolio") + (APP.eventFilter.currentPreset !== id ? `/${id}` : ""),
					params: {
						drop: ["add", "less"]
					}
				}),
				ddxClass: () => APP.eventFilter.currentPreset === id && "--selected",
				onPush: () => APP.route.hasParam("showFilter") || setTimeout(() => byId("EventList").scrollIntoView({behavior: "smooth"}), 350)
			})
		})),
		br(2),
		div("ShowHide", [
			anchor("common-GlowButton", {
				innards: () => APP.route.hasParam("showFilter") ? "Hide Filter" : "Custom Filter",
				href: () => ({
					params: { set: { showFilter: !APP.route.hasParam("showFilter") ? true : undefined }}
				})
			}),
		]),
		br(),
		tweener(() => APP.route.hasParam("showFilter") && [
			div("SkillList", () => {
				return Object.keys(SkillFilters).sort().map((skill: Skill) => anchor("Skill", {
					href: () => ({
						params: { set: APP.eventFilter.getParamSetForSkillToggle(skill) }
					}),
					ddxClass: () => APP.eventFilter.isSkillSelected(skill) && "--selected",
					innards: [
						div("SkillName", skill),
						renderSliderToggle({storage: {
							get: () => APP.eventFilter.isSkillSelected(skill),
							set: null,
						}})
					]
				}))
			}),
			// div("ShowExplicit", [
			// 	"Show Projects With Expletives",
			// 	renderSliderToggle({storage: {
			// 		get: () => true,
			// 		set: null,
			// 	}})
			// ])
		]),
		// div("FeaturedOnly", [
		// 	"Only show me extra special items"
		// ])
	])
}
