import "./fonts.scss";
import "./app.scss";
import "./scaling.scss";
import "./helium-ui-components.scss";
import "./common-components.scss";
import "./printMode.scss";


import "./polyfills";
import { addClass, derive, div, modUrl } from "helium-ui";
import { tweener } from "helium-ui-tweener";
import { renderEventListPage } from "../EventListPage/EventListPage";
import { renderTopbar } from "../Topbar/Topbar";
import { renderHeader } from "../Header/Header";
import { APP } from "src/state/AppState";
import renderWebPresencePage from "../WebPresencePage/WebPresencePage";
import { renderContactCard } from "../ContactCard/ContactCard";
import { renderResumeGenerator } from "../Resume/ResumeGenerator";
import { renderResumeListPage } from "../Resume/ResumeListPage";
import { renderCoverLetterGenerator } from "../CoverLetter/CoverLetter";


const message = String.raw`
 ___           _      ___            _
/ __| ___ _ __| |_   | _ \___ ___ __| |
\__ \/ -_) '_ \ ' \  |   / -_) -_) _' |
|___/\___| .__/_||_| |_|_\___\___\__,_|
         |_|

Hey :), welcome to my site!  If you know a bit
about how this stuff works, try typing "APP"
into the console.
`;

console.log(message);


derive(() => {
	const routeId = APP.route.id;

	if (routeId === "blog") {
		location.replace("https://patreon.com/seph_reed")
	}

	document.title = `SephReed.me - ${routeId}`
})

export function renderApp() {
	if (APP.route.getPathSlice(0) === "generate-resume") {
		return renderResumeGenerator();
	} else if (APP.route.getPathSlice(0) === "generate-cover-letter") {
		return renderCoverLetterGenerator();
	}

	return div("App", {
		ddxClass: () => APP.route.hasParam("printMode") && "--printMode",
		ref: async (ref) => {
			await document.fonts.ready;
			addClass("--fontsLoaded", ref);
		},
		ddx: [
			() => {
				if (APP.route.hasParam("showContact")) {
					APP.modals.add({
						domNode: () => renderContactCard(),
						clickOutClose: true,
						onClose: () => APP.route.urlState.followUrl(APP.route.getCurrentUrlWithParams({showContact: undefined}).href)
					})
				}
			}
		]
	},[
		APP.modals.domNode,
		APP.pointy.domNode,
		div("Background", [
			div("Hex"),
		]),
		renderHeader(),
		div("MainContent hideUntilFontsLoaded", [
			renderTopbar(),
			div("ExtraSpace", { id: "ContentTop"}),
			tweener({ ms: 150 },() => {
				if (APP.route.id === "root") {
					const portfolioUrl = APP.route.getUrlForRoute("portfolio");
					const targetUrl = APP.route.urlState.getUrlWithMods({
						path: portfolioUrl,
						hash: { existing: "keep" }
					});
					APP.route.urlState.replaceUrl(targetUrl.href);
					return;
				}
				switch (APP.route.id) {
					case "presence": return renderWebPresencePage();
					case "portfolio": return renderEventListPage();
					// case "root": return APP.route.urlState.replaceUrl();
					case "resumes": return renderResumeListPage();
					default: return "404";
				}
			})
		])
	])
}
