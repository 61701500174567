
import "./coverLetter.scss";

import { br, div } from "helium-ui";
import { renderGlowingThumbzSymbol } from "../GlowingThumbzSymbol/GlowingThumbzSymbol";
import { fromMD } from "src/LifeEventList/LifeEvent";



export function renderCoverLetterGenerator() {
	return div("CoverLetterGenerator", [
		div("Background", div("Hex")),
		div("Foreground", [
			br(),
			div("Name", [
				"SEPH ",
				renderGlowingThumbzSymbol(),
				" REED",
			]),
			br(1),
			div("LetterText", texts.estesLibraryMakerspace)
		])
	]);
}



const texts = {
	estesLibraryMarketing: fromMD(`
Greetings Estes Valley Library!  I'm Seph :)

First off, I'd like to say that the spacey aesthetic you see here is just my personal branding; one of many different styles I've worked with or am capable of.  Definitely not a style I'd use for the Library.  So, that worry aside, here's why I'm applying:

1. I love libraries
2. I love people who love libraries
3. I've spent many-a-stroll with my partner discussing how awesome libraries are and how much potential they have to move into the digital age
4. I'm a tech/art/media wizard.  (Note: wizards gain their powers through study)
5. Building and empowering community is one of my favorite hobbies
6. I'm already at the Maker Space every week
7. I am capable of creating copy in both modern conversational tone -- a favorite of social media -- and long-form prose: the standard amongst business professional contexts.
8. My last two CTO (Chief Tech Officer) positions were directly under marketing educated CEOs.  As such I've worked with a plethora of marketing, A/B testing, and analytics platforms; learning the nuances of them was a prerequisite.
9. While not formally educated in human psychology, I've spent a lot of time learning about it.  Plus my partner has a degree.
10. I have training and experience in the field of symbiotic leadership.  I never underestimate the power of a teammates creative agency or personal enjoyment in the context of pursuing goals.
11. Regardless of application outcome, I intend to help energize the already awesome Estes Valley Library on its path to becoming a even greater public center for the growth of local minds.

Voila, 11 good reasons.  Should be enough, and -- if not -- perhaps remember me whenever another position opens you think I might work out for?  That would be really sweet of you.

Thanks,<br>
Seph Reed<br>
P.S. My legal name is Scott Jaromin.  I haven't used it for eight years.

	`),
	estesLibraryMakerspace: fromMD(`
Greetings Estes Valley Library!  I'm Seph :D

First off, your maker space is my favorite place in Estes.  I built my first laser cut project there (a tiny functional movie theater), and ran into some of the most interesting of people.  It would truly be my pleasure to work with you to make it a favorite place for others as well.

In the past of I have worked at a "Tinkering School," teaching youth maker tools such as 3d printers, saws, drills, drivers, and some times even doing welding demos.  It was a summer camp style scenario where each day we'd be assigned challenges and my job was to keep everyone safe, giving just enough helpful nudges that the students were able to accomplish their projects.

A lot of the skills I've acquired for teaching came from an Americorps Leadership Development program.  We were taught to focus on empowering others, honoring the value of their experience, breaking big tasks into small, celebrating each step, and fully explaining the goals and strategies in action.

I've also gained a ton of "helper" experience in the volunteer large-scale art community.  For a few years my partner and I even co-led teams of 12-30 volunteers in building multi-story artistic structures.  Many of the volunteers came in with no prior experience and left feeling inspired, confident, safe, and knowledgeable.

Beyond teaching and tutoring skills, I also have a diverse plethora of maker skills to share including: high to low level programming, audio/music production software, video production, arduino style projects, LEDs, 3d modeling, CAD modeling, 2d raster and vector art, game development, epoxy resin, structural architecture, home repair, structural welding, fine welding, (some) perfumery, sewing, and more.  For what isn't on this list, I'm looking forward to learning it next! :p

If I get this position, one of the things I would like to offer the community is "project consulting office hours."  I have enough experience with DIY projects that I can either give helpful suggestions, or find someone who can for any project.  This is a favor I've long since offered my friends and acquaintances; it's very fulfilling to help others reach the next step when creating form from inspiration.

Another thing I would like to offer is a class on the indispensible skill of "How and where to ask questions on the internet."  It's not a common sense skill, but -- once acquired -- a person can find high quality intellectual help/direction on any subject imaginable.  I've been doing this for decades, and the difference between my ability in the past and present to get the answers I was looking for has changed dramatically.  There's a lot of tricks to it.

Finally, having spent a good amount of time in the maker space before, I'm already familiar with most of the tooling, staff, volunteers, and regulars.  

Fingers crossed,<br>
Seph
	`)
}
