import "./resumeGenerator.scss";

import { anchor, br, div, img, Innards, span } from "helium-ui";
import { APP } from "src/state/AppState";



export function renderResumeGenerator() {
	return div("GeneratedResume", {
		ddxClass: () => APP.route.hasParam("printMode") && "--variant_print_version"
	},[
		div("Background", div("Hex")),
		div("LeftCol", [
			div("Name", [
				div("MyName", "Seph Reed"),
				"legal name: Scott Jaromin",
			]),
			div("Contact", [
			`ShadyWillowCreek@gmail`, br(),
			`(737)-529-5031`,
			]),
			div("Positions", [
				{hackableHTML:
					`Full Stack Developer
					Project Lead
					UI/UX Designer
					2D/3D Digital Artist
					Fabricator
					Producer/Jazz Pianist`.split("\n").join("<br>")
				}
			]),
			div("SelfIntro", [
				`Hey there, I'm Seph 🖖🏼.  Quite the list of claims above, wouldn't you say?  Probably kind of hard to believe.  Definitely not normal.  I mean, if I were you I'd want picture evidence.  Unrelated, that's exactly what you'd find on my website... -->`,
				`Anyways, I really like engineering and systems and art.  They're all kind of the same thing to me.  Designing good systems is an art, and every art has some sort of logic to it.  Social systems are one of my favorite to work with.  My preferred style is the subtle "lead from behind" approach; it trades being given credit for more results and less effort.`,
				`In terms of priorities, mine are pretty straight forward: I enjoy being treated like a well-rounded and intelligent being.  This means having some personal agency with room for creativity, and having my thoughts met thoughtfully.  I also value getting paid; no sense in pretending jobs aren't jobs.`,
				`If you're reading resumes a bit much these days, I hope this one brought you some joy.`
			].map((blurb) => div("Blurb", blurb)))
		]),
		div("RightCol", [
			div("PrintLink", [
				"Printable version: ",
				anchor({href: "http://sephreed.me/SephReed-Resume(printer).pdf"}, "sephreed.me/SephReed-Resume(printer).pdf"),
			]),
			div("Title", "Employment History".toUpperCase()),
			div("EmploymentTable", [
				...["Positions", "Company / Info Link", "Time"].map((title) => div("TableHeader", title)),
				div("Spacer header"),
				...(() => {
					const rows = [];
					employmentHistory.forEach((employment, index) => {
						if (index > 0) { rows.push(img("Spacer", {src: "/assets/img/raw/site/resume-line-spacer.png"})); }
						const datumList = Object.keys(employment).map((prop) => div("Datum", employment[prop]));
						datumList.pop();
						rows.push(div("Employment", datumList));
					});
					return rows;
				})(),
			]),
			div("Portfolio", [
				"My portfolio showcases everything above and below",
				anchor({href: "http://SephReed.me"}, "http://SephReed.me"),
			]),
			div("Skills", [
				div("Title", "Skills".toUpperCase()),
				div("Desc", "The stars represent junior, mid, and senior levels of competency."),
				div("SkillList", skills.map((skill) => {
					let stars: string;
					switch(skill[0]) {
						case 1: stars = "<span>&#9733;</span>&#9734;&#9734"; break;
						case 2: stars = "<span>&#9733;&#9733;</span>&#9734"; break;
						case 3: stars = "<span>&#9733;&#9733;&#9733</span>"; break;
					}
					return div("Skill", [
						span("Stars", [{hackableHTML: stars}]),
						skill[1],
					]);
				}))
			])
		]),
	])
}



const createLink = (href) => anchor({href: `http://${href}`}, href);

const employmentHistory: Array<{positions: Innards, company: Innards, time: Innards, info: Innards}> = [
{
	positions: ["Technology Arts Librarian"],
	company: ["Estes Valley Library", br(), createLink("sephreed.me#EstesLibrary")],
	time: "Apr 2023 to May 2024",
	info: [{hackableHTML: `Stephanie Vyborny - artist facilitator<small>the.lost.muse@gmail.com</small>`}],
},
{
	positions: ["Senior Fullstack Software Engineer"],
	company: ["AmeriSave Mortgage Co", br(), createLink("sephreed.me#Amerisave")],
	time: "Jun-Aug 2022",
	info: [{hackableHTML: `Stephanie Vyborny - artist facilitator<small>the.lost.muse@gmail.com</small>`}],
},
// {
// 	// positions: ["CTO,", br(), "Full Stack UX Dev"],
// 	positions: ["Full Stack UX Dev"],
// 	company: ["Dot Earth Networks", br(), createLink("sephreed.me#DotEarth")],
// 	time: ["Sep 2020 to", br(), "Jan 2022"],
// 	info: [{hackableHTML: `John A. Entwistle - ceo<small>jae@coder.com</small><br>
// 		Joanne Kimmilnes - designer<small>info@joannekimmilnes.com</small>`}],
// },
{
	// positions: ["CTO,", br(), "Full Stack UX Dev"],
	positions: ["Full Stack UX Dev"],
	company: ["Choose Health", br(), createLink("sephreed.me#ChooseHealth")],
	time: ["Mar 2019 to", br(), "Jul 2020"],
	info: [{hackableHTML: `John A. Entwistle - ceo<small>jae@coder.com</small><br>
		Joanne Kimmilnes - designer<small>info@joannekimmilnes.com</small>`}],
},
{
	positions: ["Frontend Dev,", br(), "UX Team"],
	company: ["Coder Technologies", br(), createLink("sephreed.me#CoderComDev")],
	time: "Jan-Oct 2018",
	info: [{hackableHTML: `John A. Entwistle - ceo<small>jae@coder.com</small><br>
		Joanne Kimmilnes - designer<small>info@joannekimmilnes.com</small>`}],
},{
	positions: ["Build Lead,", br(), "Codesigner"],
	company: ["Austin Artistic Reconstruction LLC", br(), createLink("sephreed.me#Cononagon")],
	time: "Oct-May 2018",
	info: [{hackableHTML: `Adam Rice - board member<small>adamrice@8stars.org</small>`}],
},
// {
// 	positions: ["Full Stack Dev,", br(), "Fabricator"],
// 	company: ["Contract Interactive Light Fabrication", br(), createLink("sephreed.me#DadLight")],
// 	time: "Jan-Aug 2017",
// 	info: [{hackableHTML: `Sally Hall - client<small>sally.hall@austincenterfordesign.com</small>`}],
// },
{
	positions: ["Build Lead,", br(), "Codesigner"],
	company: ["Austin Artistic Reconstruction LLC", br(), createLink("sephreed.me#PlaywoodPalace")],
	time: "Oct-May 2017",
	info: [{hackableHTML: `Stephanie Vyborny - artist facilitator<small>the.lost.muse@gmail.com</small>`}],
},
// {
// 	positions: ["Web Dev,", br(), "3D Modeling"],
// 	company: ["Stash Crypto", br(), createLink("sephreed.me#Stash")],
// 	time: "2016",
// 	info: [{hackableHTML: `Anonymity desired`}],
// },{
// 	positions: ["Full Stack Dev"],
// 	company: ["Realme App", br(), "(Design School Final Project)"],
// 	time: "Spring 2016",
// 	info: [{hackableHTML: `Misty Nickle - client<small>mistynickle@gmail.com</small>`}],
// },{
// 	positions: ["Instructor,", br(), "Fabricator"],
// 	company: ["Austin Tinkering School"],
// 	time: "Summer 2016",
// 	info: [{hackableHTML: `Kami Wilt - owner<small>kami@austin.tinkeringschool.com</small>`}],
// },
{
	positions: ["Forest Service,", br(), "Team Leading"],
	// company: ["	Northwest Youth Corps.", br(), "Leadership Development"],
	company: ["Americorps Leadership Development", br(), createLink("sephreed.me#AmericorpLDP")],
	time: "Fall 2014",
	info: [{hackableHTML: `Jon Zintell - leader<small>jon@nwyouthcorps.org<small>`}],
},
{
	positions: ["Comp Sci Major"],
	company: ["B.S. from Portland State University"],
	time: "2013",
	info: [{hackableHTML: `Stephanie Vyborny - artist facilitator<small>the.lost.muse@gmail.com</small>`}],
},
{
	positions: ["Self Teaching"],
	company: ["My first websites and games"],
	time: "2000 - 2001",
	info: [{hackableHTML: `Stephanie Vyborny - artist facilitator<small>the.lost.muse@gmail.com</small>`}],
},
]




// const skills: Array<[number, string]> = [
// 	[3, "Typescript (2018)"],
// 	[2, "Sass (2018)"],
// 	[3, "React (2018)"],
// 	[1, "Webpack (2018)"],
// 	[2, "Arduino / FastLED (2018)"],
// 	[1, "C (2018)"],
// 	[2, "Circuitry (2017)"],
// 	[2, "WebWorkers (2017)"],
// 	[2, "Signal Processing (FFT)(2017)"],
// 	[3, "Volunteer Leadership (2017)"],
// 	[3, "Project Management (Foreman)(2017)"],
// 	[3, "Web Audio API (2017)"],
// 	[2, "Basic Cryptology (AES-EBC)(2016)"],
// 	[2, "Blender 3d (2016)"],
// 	[3, "Teaching (2016)"],
// 	[2, "Three.js / WebGl (2016)"],
// 	[2, "Mapping / Geolocation API (2016)"],
// 	[2, "Home Renovation (2016)"],
// 	[2, "Carpentry (2014)"],
// 	[3, "Leadership (2014)"],
// 	[3, "Bitwig (DAW)(2014)"],
// 	[3, "BS in Computer Science from Portland State University (2013)"],
// 	[2, "Cooking (2012)"],
// 	[1, "Python (2012)"],
// 	[3, "Svg / Vector Art (2012)"],
// 	[2, "SQL (2012)"],
// 	[3, "CSS (2011)"],
// 	[2, "Jazz Theory (2010)"],
// 	[2, "PHP (2010)"],
// 	[3, "Javascript (2010)"],
// 	[2, "Fire Performance (2009)"],
// 	[2, "C++ (2009)"],
// 	[2, "Bash (Unix/Linux)(2009)"],
// 	[2, "Java (2008)"],
// 	[3, "Photoshop / Gimp (2007)"],
// 	[3, "SketchUp (CAD)(2007)"],
// 	// [1, "Auto-Cad (2007)"],
// 	[3, "Digital Audio Workspaces (2007)"],
// 	[2, "3d Modeling (2004)"],
// 	[2, "Guitar (2004)"],
// 	[3, "HTML (2002)"],
// 	[3, "Piano (2000)"],
// 	[1, "Sandbox Java (2000)"],
// ]


const skills: Array<[number, string]> = [
	[2, "2d Art"],
	[1, "3d Modeling"],
	[2, "Audio"],
	[3, "APIs"],
	[1, "Architecture"],
	[3, "Bitwig"],
	[1, "Blender"],
	[2, "C++"],
	[3, "Collaboration"],
	// [2, "Civil Servant"],
	[3, "Documentation"],
	[3, "Event Systems"],
	[2, "Fabrication"],
	[2, "Games"],
	[3, "Helium UI"],
	[2, "HTML5 Canvas"],
	[3, "Interactive Art"],
	[2, "Integrations"],
	[1, "Java"],
	[2, "Jazz Theory"],
	[2, "Linux"],
	[3, "Low-Level Web"],
	[2, "MapBox"],
	[2, "Mentorship"],
	[2, "Mobile Apps"],
	[2, "Mix Mastering"],
	[2, "Micro-services"],
	[1, "Micro-controllers"],
	[2, "NativeScript"],
	[3, "Onboarding"],
	[3, "Node JS"],
	[3, "Peer Leadership"],
	[1, "Procedural Gen."],
	// [2, "Performance"],
	[1, "Python"],
	// [2, "React"],
	[2, "Roadmapping"],
	[2, "RegEx"],
	[3, "Sass"],
	[2, "Servers"],
	[2, "SketchUp"],
	[2, "Sketch App"],
	[2, "Signal Processing"],
	[2, "Stakeholder Mgmt."],
	[2, "Stripe"],
	[2, "Structural Eng."],
	[2, "SQL"],
	[1, "Syntax Parsing"],
	[3, "Synths"],
	[3, "Task Delegation"],
	[2, "Three JS"],
	[3, "TypeScript"],
	[3, "UX"],
	[3, "Web App"],
	// [3, "Website"],
	[2, "Web Workers"],
	[3, "Web Audio API"],
	[3, "Web Extensions"],
	[1, "Video"],
	[3, "Volunteer"],
	[3, "Vanilla JS"],
]
