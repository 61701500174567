if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}



if (CSS && CSS.supports && CSS.supports("position", "sticky")) {
  // awesome: position:sticky is supported on this browser!
} else {
  noSupport();
}

function noSupport() {
	confirm(`Hey, my apologies, but your browser appears to be missing some important (over 5 year old) features and this site won't look too good without them.  Would you like to see a pdf version instead?`) && (location.pathname = "/no-js.html")
}

check_webp_feature('lossy', function (feature, isSupported) {
	!isSupported && noSupport();
});

function check_webp_feature(feature, callback) {
	var kTestImages = {
			lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
			lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
			alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
			animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
	};
	var img = new Image();
	img.onload = function () {
			var result = (img.width > 0) && (img.height > 0);
			callback(feature, result);
	};
	img.onerror = function () {
			callback(feature, false);
	};
	img.src = "data:image/webp;base64," + kTestImages[feature];
}
