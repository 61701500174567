import "./contactCard.scss";

import { anchor, br, div, img } from "helium-ui";



export function renderContactCard() {
	return div("ContactCard", [
		div("Bg"),
		div("Fg", [
			div("Name", ["Seph", img({src: "/assets/svg/thumbz_symbol (black-stroke).svg"}), "Reed"]),
			div("Brag", "Artist, Leader, and Full Stack UX Dev"),
			br(2),
			anchor("PhoneNum", {
				href: "tel:+7375295031",
				innards: "(737) 529 - 5031"
			}),
			div("Email", ["Shady", "Willow", "Creek", "@gmail.com"].join("")),
		])
	])
}
