import "./eventList.scss";

import { anchor, br, DerivationManager, div, el, externalLink, img, Source } from "helium-ui";
import { APP } from "src/state/AppState";
import { renderEventFilter } from "../EventFilter/EventFilter";
import { renderHeader } from "../Header/Header";
import LifeEventList, { ILifeEvent } from "src/LifeEventList";
import { tweener } from "helium-ui-tweener";



export function renderEventListPage() {
	return div("EventListPage", [
		renderEventFilter(),
		renderEventList(),
	])
}




// const eventRenders = new Map<string, HTMLDivElement>();

export function renderEventList() {
	const preset = new Source<string>();
	// const isFocused = (ev: ILifeEvent) => APP.route.urlState.hash === `#${ev.id}`;

	return div("EventList", {
		id: "EventList",
		ddxClass: () => (preset.get() === "all") && "--showAll",
		ddx: () => preset.set(APP.route.getPathSlice(1))
	},[
		// div("Background",[
		// 	div("Sticky", [
		// 		div("Forest"),
		// 		div("Hex"),
		// 	])
		// ]),
		div("Foreground", [
			() => APP.route.hasParam("printMode") && div({
				style: "text-align: center",
				innards: () => {
					const preset = APP.route.getPathSlice(1);
					return preset === "all" ? `(no portfolio events filtered)` : `(portfolio events related to ${preset})`;
				}
			}),
			div("LifeEvent byMe", {
				ddxClass: () => preset.get() && "--show",
				innards: div("Text", [
					`Unless otherwise stated: please assume that any code, iconography, illustrations, modeling,
					or design shown below was created personally and from scratch.`
				])
			}),
			...LifeEventList.map((ev) =>
				renderLifeEvent(
					ev,
					() => preset.get() === "all" || APP.eventFilter.someSkillsSelected(ev.skills)
				)
			)
		]),
		// div("Foreground", LifeEventList.map((ev) =>
		// 	() => {
		// 		if (preset() === "all" || APP.eventFilter.someSkillsSelected(ev.skills)) {
		// 			if (eventRenders.has(ev.id) === false) {
		// 				eventRenders.set(ev.id, renderLifeEvent(ev));
		// 			}
		// 			return eventRenders.get(ev.id)
		// 		}
		// 	}
		// ))
	])
}



function renderLifeEvent(ev: ILifeEvent, noFilter: () => boolean) {
	const blur = new Source(!!ev.hasExpletives);

	const isShowing = new Source(false);
	const isFocused = new Source(false);

	function month(date: Date) {
		return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][date.getMonth()];
	}

	return div("LifeEvent", {
		id: ev.id,
		ddxClass: [
			() => isShowing.get() && "--show",
			() => blur.get() && "--blur",
		],
		ddx: [
			() => isShowing.set(noFilter() || APP.eventFilter.someSkillsSelected(ev.skills) || isFocused.get()),
			() => isFocused.set(APP.route.urlState.hash === `#${ev.id}`)
		]
	}, () => isShowing.get() && [
		div("Unblur", {
			innards: ["Heads up!", br(), "This item includes expletives.", br(), "Click here to unblur."],
			onPush: () => blur.set(false),
		}),
		div("TopItems", [
			div("Title", ev.title),
			div("Date", () => {
				const start = ev.startDate;
				const end = ev.endDate;
				if (!end) {
					return `${month(start)} ${start.getFullYear()}`;
				} else if (end.getFullYear() === start.getFullYear()) {
					if (start.getMonth() === end.getMonth()) {
						return `${month(start)} ${start.getFullYear()}`;
					}
					return `${month(start)} - ${month(end)} ${start.getFullYear()}`;
				} else {
					return `${month(start)} ${start.getFullYear()} - ${month(end)} ${end.getFullYear()}`;
				}
			})
		]),
		br(0.5),
		div("ShortDesc", ev.shortDesc),
		br(0.5),
		// ev.skills && div("Skills", [
		// 	"Skills Utilized: ",
		// 	ev.skills.join(", ")
		// ]),
		ev.images && div("Images", {
			ddxClass: [
				() => ev.images.length <= 1 && "singleColumn",
				() => ev.images.length === 3 && "centerThird",
			]
		}, ev.images.map((image, index) => {
			let src: string;
			let backupSrcs: string[] = [];
			let isVideo = false;
			if (image instanceof HTMLImageElement) {
				return image;
			}

			const name = typeof image === "string" ? image : image.contain;
			let size = "500";
			if (name.startsWith("http")) {
				src = name;
			} else {
				if (window.innerWidth < 350) {
					size = "300";
				} else if (window.innerWidth < 600) {
					const numImages = ev.images.length;
					if (numImages > 1 && (index !== numImages - 1 || !(numImages % 2))) {
						size = "300";
					}
				}
				const path = "/assets/";
				if (name.endsWith(".gif")) {
					src = `${path}gif/${size}/${name}`;
					// src = path + "gif/optimized/" + name;
					// backupSrcs.push(path + "gif/raw/" + name)
				} else if (name.endsWith(".svg")) {
					src = path + "svg/" + name;

				} else if (name.endsWith(".mov")) {
					src = path + "video/raw/" + name;
					isVideo = true;

				} else {
					src = `${path}img/${size}/${name}`;
				}

				backupSrcs.push(src.replace(/\/\d+\//, "/raw/"));
			}
			if (isVideo) {
				return div("ImageContainer", [
					div("Bg", div("Hex")),
					el("video", {
						attr: {
							src,
							controls: "",
							type: "video/mp4"
						}
					})
				])
			}
			return externalLink("ImageContainer", {
				href: src.replace(/\/\d+\//, "/raw/"),
				attr: {
					alt: `View image in full size`
				},
				innards: [
					div("Bg", div("Hex")),
					img({
						src: src.replace(/\.(gif|jpg|jpeg|png)$/i, ".webp"),
						backupSrcs,
						loading: "lazy",
						attr: {
							width: size,
							height: size
						}
					})
				],
			}) ;
		})),

		tweener(() => (isFocused.get() || APP.route.hasParam("printMode")) && div("Details", [
			ev.desc && div("LongDescription", ev.desc),
			() => ev.links && div("Links", [
				div("Title", "More Info"),
				ev.links,
				br(2),
			])
		])),

		div("BottomItems", [
			 div("Tags", ev.skills && [
				"Skills Used: ",
				() => ev.skills.sort((a, b) => {
					const aSel = APP.eventFilter.isSkillSelected(a);
					const bSel = APP.eventFilter.isSkillSelected(b);
					if (aSel === bSel) {
						return 0;
					}
					return aSel ? -1 : 1;
				}).map((tag) =>
				 anchor("Tag", {
					 ddxClass: () => APP.eventFilter.isSkillSelected(tag) && "--selected",
					 innards: tag,
					 href: () => ({
						 params: { set: APP.eventFilter.getParamSetForSkillToggle(tag) }
					 })
				 })
			 )
			]),
			anchor("ReadMore common-GlowButton", {
				href: () => APP.route.urlState.getUrlWithMods({hash: isFocused.get() ? { existing: "drop" } : ev.id }),
				preventHashFocus: true,
				innards: () => isFocused.get() ? "Hide Details" : "View Details",
			})
		])
	])
}
