import { IParamUpdateArgs, Stringable } from "helium-ui";
import { Skill, SkillFilters } from "src/LifeEventList/LifeEvent";
import { APP } from "./AppState";
import { UrlParam } from "./RouteState";












export const FilterPresetIdList = [
	"featured",
	"leadership",
	"tech",
	"art",
	"music",
	"all",
	"custom"
] as const;

export type FilterPresetId = typeof FilterPresetIdList[number];


export class EventFilterState {
	constructor() {}

	public get currentPreset() {
		if (APP.route.getPathSlice(0) === "portfolio") {
			return APP.route.getPathSlice(1) as FilterPresetId || null;
		}
		return null;
	}

	public get addedSkills(): Skill[] {
		const add = APP.route.params.add;
		return add ? add.split(",") : [] as any;
	}


	public get removedSkills(): Skill[] {
		const less = APP.route.params.less;
		return less ? less.split(",") : [] as any;
	}

	public isSkillSelected(skill: Skill) {
		const preset = this.currentPreset;
		if (preset === "all") {
			return true;
		} else if (this.addedSkills.includes(this.skillToURL(skill))) {
			return true;
		} else if (this.removedSkills.includes(this.skillToURL(skill))) {
			return false;
		}

		return SkillFilters[skill].includes(preset);
	}

	protected skillToURL(skill: Skill) {
		return skill.replace(/ +/g, "-").trim().toLowerCase() as Skill;
	}

	public getParamSetForSkillToggle(_skill: Skill): Partial<Record<UrlParam, Stringable>> {
		// const isSelected = this.isSkillSelected(skill);
		const removed = this.removedSkills;
		const added = this.addedSkills;
		const skill = this.skillToURL(_skill);
		if (added.includes(skill)) {
			return {add: added.filter((it) => it !== skill)};
		} else if (removed.includes(skill)) {
			return {less: removed.filter((it) => it !== skill)};
		}

		if (this.isSkillSelected(_skill)) {
			return {less: removed.push(skill) && removed }
		} else {
			return {add: added.push(skill) && added}
		}
	}

	public someSkillsSelected(skills: Skill[] = []) {
		return skills.some((skill) => this.isSkillSelected(skill));
	}
}





// export const FilterPresets: Array<{
// 	id: FilterPresetId;
// 	tags: "all" | FilterTag[];
// }> = [
// 	{ id: "all",
// 		tags: "all",
// 	},
// 	{ id: "art",
// 		tags: []
// 	},
// 	{ id: "tech",
// 		tags: ["code", "circuits"],
// 	},
// 	{ id: "leadership",
// 		tags: ["leadership","collaborative"],
// 	},
// ]

// export class EventFilterState {
// 	protected state = Sourcify({
// 		presetId: null as null | FilterPresetId,
// 		tags: [] as "all" | FilterTag[],
// 	});


// 	public get presetId() { return this.state.presetId; }

// 	public set presetId(id: FilterPresetId | null) {
// 		console.log(id);
// 		if (id === null) {
// 			this.clear();
// 			return;
// 		}

// 		const preset = FilterPresets.find((it) => it.id === id);
// 		this.state.presetId = preset.id;
// 		const { tags } = preset;
// 		const items = Array.isArray(tags) ? [...preset.tags] as FilterTag[] : tags;
// 		this.state.set("tags", items);
// 	}

// 	public clear() {
// 		this.state.upsert({presetId: null, tags: null });
// 	}
// }
