import { div, externalLink } from "helium-ui";
import { ILifeEvent, twoColLayout, extLink, fromMD } from "./LifeEvent";

const JobEvents: ILifeEvent[] = [];

/*****************
 *. Tech Arts
 ******************/
JobEvents.push({
  id: "EstesLibrary",
  startDate: new Date("April 1 2023"),
  endDate: new Date("May 31 2024"),
  title: "Technology Arts Librarian at Estes Valley Library",
  shortDesc: "Running a Makerspace, and developing educational programs.",
  skills: [
    "Featured ⭐️", "2d Art", "3d Modeling", "Civil Servant", "Collaboration", "Documentation", "Fabrication",
    "Laser Cutting", "Onboarding"
  ],
  images: [
    "tech-jobs/evpl-laser.png",
    "tech-jobs/evpl-3d.png",
    "tech-jobs/evpl-programs.png"
  ],
  links: () => [
    externalLink({ innards: "Public Files and Documentation", href: "www.estesvalleylibrary.org/makerfiles"}),
    externalLink({ innards: "Estes Valley Library Website", href: "www.estesvalleylibrary.org"})
  ],
  desc: () =>
    fromMD(`
Best job I could ever have asked for, I got to run a Makerspace!  The primary duties of the job were keeping the space
going, and coming up with educational programs to help introduce people to the space.

I had never been much of a crafts person, often focusing on "tougher" art mediums.  Within my year in this position,
I have learned every craft I could ever want.  I've also become extremely proficient with a Laser Cutter.

The thing I'm most proud of from this job is the "Make & Take" kits.  I developed many programs that had low skill floors, high skill ceilings, and minimal cost per patron.  I then taught them various times and refined my documentation until it was possible for patrons to finish projects without any help.  I started a system by which patrons can come to the Makerspace, choose a project, be given the materials and instructions, and learn a new skill.  Much of the documentation is available in the "makerfiles" link below.
	`),
});

/*****************
 *. Snow Making
 ******************/
JobEvents.push({
  id: "EldoraSnow",
  startDate: new Date("Nov 26 2022"),
  endDate: new Date("Feb 16 2023"),
  title: "Snow Maker at Eldora",
  shortDesc: "Twelve hour night-shifts maintaining snow guns at a ski resort.",
  skills: [
    "Collaboration", "Grit", 
    // "TypeScript", "Node JS", "APIs", "Architecture", "Collaboration",
    // "Event Systems", "Integrations", "Micro-services", "SQL", "Onboarding",
    // "Peer Leadership", "Servers"
  ],
  images: [
    "other/eldora-snowmaking.png",
    "other/eldora-jerry.png",
    "other/eldora-big-spray.png",
  ],
  links: () => [
    externalLink({ innards: "Eldora Website", href: "www.eldora.com"})
  ],
  desc: () =>
    fromMD(`
Between jobs after the mass layoffs post covid, a friend offered me the strangest job opportunity I had ever heard:
staying up all night making snow.  The hours were insane, the work was exhausting, and the pay was meager.  It was also
a once in a lifetime experience, and I had to take it.

This job gave me some extremely fond memories.  Watching sunrises, wading through snow under the moonlight, machinery
as loud as jet engines, white knuckling on ski mobiles, learning to fight extreme coldness, weird sleep schedules,
riding a ski lift into the stars... it was
unlike anything I'd ever experienced.  The best I could explain it is as mining on the moon.

I can't say I gained much more from this job beyond interesting experiences and the knowledge that my body can handle
some pretty extreme conditions.  It was often miserable, but in a way that made me feel alive.
	`),
});

/*****************
 *. Amerisave
 ******************/
JobEvents.push({
  id: "Amerisave",
  startDate: new Date("Jun 6 2022"),
  endDate: new Date("Aug 22 2022"),
  title: "Senior Software Engineer at Amerisave",
  shortDesc:
    "Working with an in-house framework for highly modular service based Mortgage Loan Officer tools.",
  skills: [
    "Featured ⭐️", 
    "TypeScript",
    "Node JS",
    "APIs",
    "Architecture",
    "Collaboration",
    "Event Systems",
    "Integrations",
    "Micro-services",
    "SQL",
    "Onboarding",
    "Peer Leadership",
    "Servers",
  ],
  images: ["tech-jobs/amerisave-card.png"],
  desc: () =>
    fromMD(`
Perhaps the tail end of low APRs and beginning of aggressive inflation squashing wasn't the best time to enter the mortgage industry.  I was only onboard for a few months before sky-rocketing APRs and sky-diving home buyer rates led to our entire team being laid off, amicably in my case.

During my brief stint, I managed to accomplish a fair amount including:
- an overhaul of our new dev onboarding process
- the completion of "Concession Request" service (Loan Officer stuff)
- the collection of integration requirements and architecture design for switch over to Surefire (marketing tool)
- the investigation and documentation of a previously opaque in-house framework
- generally making a lot of peoples days better

This was first foray into a large corporation, and I found the change of pace as compared to start-ups striking.
	`),
});

/*****************
 *.  Dot Earth
 ******************/
JobEvents.push({
  id: "DotEarth",
  startDate: new Date("Sep 14 2020"),
  endDate: new Date("Jan 1 2022"),
  title: "CTO at Dot Earth Networks",
  shortDesc:
    "Sole developer at social change start-up aiming to create collaboration between NGOs.",
  skills: [
    "MapBox",
    "Helium UI",
    "TypeScript",
    "Node JS",
    "Servers",
    "APIs",
    "Stakeholder Mgmt.",
    "Web App",
    "Peer Leadership",
  ],
  images: ["Dot-Earth.gif"],
  desc: () =>
    fromMD(`
**Heads up:** due to funding issues, the project was not completed and there isn't much to show for the work.

The start of *this job was extremely serendipitous*.  Boulder Dot Earth had been around for a long time, but had been lying dormant for the last few years.  So Micha (CTO) got in contact with the originators to see if he could pick it up again, this time with newer tech.

In it's old form, it was a Wordpress site with event calendars for local non-profits, a directory of them, and some info on the generalized goals of the city.  But *Micha wanted something a bit more tech heavy*, something with maps and a Single Page App (SPA) design.  As it turned out, *I was working on a personal project with these exact properties when we met*.

My knowledge of the non-profit space was very limited, having come largely from a warless volunteer back-ground (art and firefighting).  *Micha educated me on how dire the non-profit situation is*.  Through his understanding of the space, and my ability to abstract and solve problems we came up with a solution.

The gist of **Dot Earth Networks** was to create *a mobile/web app that connected various loose ends together, creating a greater force for good*.  The core goal was to get non-profits to *collaborate*, instead of being at war.  But we also wanted to weave in *local voices, philanthropists, businesses, and government*.  The exact details of how to build this symbiotic network are a bit of a secret (as centralized political power strategies should be).  But it was a good idea.

Micha secured a grant from the City of Boulder, and a collaboration with a local college to create the "Dot Earth Hive" where us and a small group of volunteers attempted to build something of *a social-good tech-startup*.

------------

Unfortunately, while the network and tech skills were there, the money was not.  I had to put in a three-month notice, which led to about four months of support trying to push it forwards.  But as the only tech/business savvy participant, I was simultaneously the only person working on the actual product and heavily out-voted in regards to priorities.  The appeal of the work drained tremendously.

In the end, I still love the idea and came out of the experience *enriched with a new understanding of the non-profit world*.
	`),
});

/*****************
 *.  Choose Health
 ******************/
JobEvents.push({
  id: "ChooseHealth",
  startDate: new Date("March 11 2019"),
  endDate: new Date("July 1 2020"),
  title: "CTO at Choose Health",
  shortDesc: `Sole developer for a subscription based at-home blood testing start-up aiming to monitor health and give personalized recommendations.`,
  skills: [
    "Featured ⭐️", 
    "Mobile Apps",
    "Micro-services",
    "Integrations",
    "Event Systems",
    "Stripe",
    "TypeScript",
    "NativeScript",
    "Node JS",
    "SQL",
    "Servers",
    "APIs",
    "Stakeholder Mgmt.",
    "Roadmapping",
    "Web App",
    "Website",
  ],
  images: [
    "tech-jobs/choose-health-web-app.png",
    "tech-jobs/choose-health-mobile-app.png",
  ],
  desc: () =>
    fromMD(`
This was the first job I ever landed through LinkedIn.  Choose Health was a health-sector tech start-up birthed by **Mark** (CEO), a guy who'd long been working in the field of supplements and marketing.  The impetus was pretty straight-forward: *he wanted to prove the positive health benefits of supplements*.  And what better way than by tracking and measuring health?

When I was first interviewed, Mark and Sam (PO) had already spoken with physicians and ran a pilot.  They wanted to prove that *blood could be used to measure health*, and that regular measurements could add value to users lives.  The results were mixed, but they saw promise and *needed a full-stack, do-everything dev to make it happen*.  In the end, I developed all of the following:
- A native *mobile-app for IOS and Android* which displayed blood results and recommendations
- Multiple refactors of a product site (given to me as plain HTML, eventually made an SPA, then migrated into *Webflow* for easier PO edits)
- The entire *back-end, including HIPAA* security compliance
- Untold integrations for *payments (**Stripe**), emails (product and transactional)*, analytics, shipping and tracking, user feed-back and communications, ratings...
- *An entire separate micro-service* called "Diagnostic Infrastructure" which existed to connect doctors and in-need-of-release blood results.  It was extremely asynchronous with extensive "user error" corrective measures.  It had it's own front and back ends... it was a full project on its own.
- And, lastly, a *web-app* version of the mobile app

-----

As I was approaching my 30th birthday, I took stock of my life and decided that -- while I was very happy with the quality of my work and freedom to pursue interesting solutions to problems -- I wanted to turn a new page.  I put in my three month notice, *ended work on my 30th birthday then moved up into the Colorado Rocky Mountains*.  I learned an absolute mountains worth while working there.
	`),
});

JobEvents.push({
  id: "BradPorch",
  startDate: new Date("Jan 21 2017"),
  endDate: new Date("Aug 11 2017"),
  title: `"Zen Style" Porches`,
  skills: [
    "Fabrication",
    "3d Modeling",
    "SketchUp",
    "Architecture",
    "Structural Eng.",
    "UX",
  ],
  images: [
    "big-builds/brad-porch-backyard.jpg",
    "big-builds/brad-porch-frontyard.jpg",
  ],
  shortDesc:
    "Some front and back porches, inspired by the architecture of Japanese Gardens.",
  desc: () =>
    fromMD(`
A friend of a friend -- Brad -- was looking for some artistic builder types to make his home a little more interesting.  In particular, his front and back porch.  At the same time, I was between jobs, so we got together to design and build this little project.  *Inspired by Japanese garden architecture, the support beams are hidden from view and the angles are rarely orthogonal*.  It was a new style for me, but it came out great!  He still makes sure to bring up how much he likes it every time we run into each-other.
	`),
});

JobEvents.push({
  id: "CoderComDev",
  startDate: new Date("Jan 15 2018"),
  endDate: new Date("Sep 25 2018"),
  title: "Front-End Developer at Coder Technologies",
  shortDesc:
    "At a tech start-up, assigned to develop a browser-embedded IDE and a CRUD site; ended up filling a bunch of other roles.",
  images: ["tech-jobs/CoderLogo(square).png", "tech-jobs/CoderInterior.jpg"],
  skills: [
    "Featured ⭐️", 
    "TypeScript",
    "Sass",
    "React",
    "Node JS",
    "Vanilla JS",
    "Audio",
    "Bitwig",
    "Documentation",
    "Web App",
  ],
  links: () => [
    externalLink({
      innards: "Coder.com *",
      href: "http://coder.com",
    }),
    externalLink({
      innards: "Watch product video I made the music for",
      href: "https://www.youtube.com/watch?v=CMKRbc8DpVs",
    }),
    externalLink({
      innards: "Link to song from video above",
      href: "https://soundcloud.com/thumbz/forestbackground",
    }),
  ],
  desc: () =>
    fromMD(`
When I started at Coder there were less than a dozen employees, so I got to fill many roles besides front-end developer.  Our mission was to create a collaborative cloud based software development suite, including in browser IDE and remote compilation/storage/hosting. Some of the projects I did include:
<br><br>
- Site wide theming, procedurally generated from Textmate/Bash themes (see Lumas)
- Many internal pages of outstanding quality (as said by the designers and QA)
- Major site components such as Button, Link, EditableText, ValidatorInput, PageTween, MinimizableDiv, and Modal
- A keyboard navigation system for the IDE
- Background/intro/outro music for company promo/tutorial vids
- Original concepting for landing page
- Some major influences in our workflow and timeline
- Head of Easter Eggs
- A few very nice blog posts
	`),
});

JobEvents.push({
  id: "AustinConstruction",
  startDate: new Date("Jan 21 2017"),
  endDate: new Date("Aug 11 2017"),
  title: `Subcontractor in Home Renovation`,
  skills: ["Fabrication", "Grit"],
  images: ["Daniel-Subcontractor-Cedar-Deck.gif"],
  shortDesc: "Home renovation projects, working for Daniel Jost",
  desc: () =>
    fromMD(`
This was just your basic construction job.  We messed with wood, drywall, structural stuff, plumbing, and wires. *The only curve-ball was that we were working on peoples homes while they were there*, so we had to be extra clean, mindful of noise, and have attention to detail.
	`),
});

JobEvents.push({
  id: "DadLight",
  startDate: new Date("Jan 21 2017"),
  endDate: new Date("Aug 11 2017"),
  title: `Independent Contractor for Project "Dad Light"`,
  skills: [
    "Web Workers",
    "Linux",
    "Micro-controllers",
    "Node JS",
    "Signal Processing",
    "Fabrication",
    "Mobile Apps",
    "UX",
    "Web Audio API",
  ],
  images: ["DadLight-Glamor(loop).gif", "tech-jobs/DadLight-Internal.jpg"],
  shortDesc:
    "A custom built audio-reactive ceiling light fixture, with an app for control.",
  desc: () =>
    fromMD(`
Sally Hall was just finishing up her classes at Austin Center for Design and had a wonderful idea of a gift to make her father.  The basic premise was this:  *Her father was an audiologist, so she wanted to make him a ceiling light which responded to sound* similar to the way the hairs in our ears do while playing music.

She spread this idea broadly, looking for someone who could make it happen (on a college student's budget), and found me.  We met over coffee and discussed its feasibility, then I used sketchup to design the project alongside her.  What we came up with was a seamless box containing *a raspberry pi, break-out board, and power supply that would hang from the ceiling*.  Extending out of this box was twelve hanging jar lamps with fairy lights inside, each lamp representing a tone from the western 12 tone scale.

In order to make the lights play music, I developed a webapp which was served from the pi, in which Sally could choose songs, run patterns, turn on mic input, and control things like brightness, on/off, and fade time.  The *hardest parts of this project were getting the FFT to be performant, and designing the 12 female-USBs breakout board* which had to use MOSFETs to step up the pi's 3v3 GPIOs to the 5v power supply for the lights.
`),
});

/*********************
 *   STASH CRYPTO
 **********************/
JobEvents.push({
  id: "Stash",
  startDate: new Date("Oct 8 2016"),
  title: "Web Developer at Stash Crypto Inc",
  images: [
    "tech-jobs/StashLogo.png",
    "stash_node_site.gif",
    "digiden_loop.gif",
  ],
  shortDesc:
    "Sole developer for various web services related to a crypto cold-wallet; also did some 3d product modeling and renders.",
  skills: ["Vanilla JS", "3d Modeling", "Blender", "Website"],
  links: () => [
    externalLink({
      innards: "StashCrypto.com",
      href: "https://stashcrypto.com",
    }),
    externalLink({
      innards: "View product page prototype",
      href: "http://sephreed.github.io/stash_node/index.html",
    }),
    externalLink({
      innards: "View digital identity search engine prototype",
      href: "http://sephreed.github.io/Digiden/index.html",
    }),
    externalLink({
      innards: "Cryptopals crypto challenges",
      href: "https://cryptopals.com/",
    }),
  ],
  desc: () =>
    fromMD(`
I met the CEO of Stash Crypto at a crypto-currency meetup held at the Factom offices, and we hit it off pretty well.  He happened to be in need of a web developer at the time.  The company direction was often tumultuous, leading ultimately to the disposal of the company identity in an attempt to mimic Apple (a common story in start-ups).  I made a 3d model of our original logo and our flagship product, as well as a website for an internet alliance they were attempting to start, prototypes for the digital identity search engine (with AES-EBC encrypted local storage of searches) and the product landing page (which includes the product 3d renders).  While working there, I started to really study security and cryptology, specifically through the cryptopals challenges.
`),
});

JobEvents.push({
  id: "TinkeringSchool",
  startDate: new Date("Jun 26 2016"),
  endDate: new Date("Aug 7 2016"),
  title: `Teacher at Tinkering School`,
  skills: ["Featured ⭐️", "Mentorship", "Task Delegation", "Peer Leadership"],
  images: [
    "/other/tinkering-school-logo.png",
    "/other/tinkering-school-child-launch.jpg",
  ],
  shortDesc: `An after-school "maker style" class for introducing power tools, 3d printers, and general building skills to children.`,
  desc: () =>
    fromMD(`
Don't worry, my hand was always the one holding the boards down on the chop saw!  Such a fun job..

Tinkering School was a sort of summer-camp for tinkering.  *We brought in various age group classes, ranging from 4 - 13, and gave them building challenges and a shop*.  For the younger ones, it was mostly just hot glue.  But for 6 and up we started letting them use drills, hammers, saws... things most parents found astounding when they came in to watch for the last day.

As unsafe as all this sounds, no serious injuries ever occurred.  We kept a close eye on the kids all the time, gave them extra small tools, and taught them to do things in ways that avoided risk.  If they hammered a finger, we'd get them laughing and smiling again by the end of the day.

My favorite challenges to lead were fort building, and raft building.
	`),
});

JobEvents.push({
  id: "AmericorpLDP",
  startDate: new Date("Sep 22 2014"),
  endDate: new Date("Nov 11 2014"),
  title: `Americorp Leadership Development Program`,
  skills: ["Featured ⭐️", "Peer Leadership", "Grit"],
  images: ["other/americorps-logo.png", "other/americorps-saws.jpg"],
  links: () => [
    externalLink({
      innards: "Photo Album from Season",
      href: "https://northwestyouthcorps.zenfolio.com/p869056121",
    }),
  ],
  shortDesc:
    "Six weeks in the woods, rehabbing meadows and studying the art of good leadership.",
  desc: () =>
    fromMD(`
How do you get a group of eight ill-behaved teenagers who've been forced to do manual labor for less than minimum wage to *not only meet quotas but have a good time*?  The answer: really good leadership.

I don't know if every Americorps group is awesome (we certainly met some groups that seemed less so), but *our leader John was amazing*.  He could have easily led any team, but stuck to Americorps because "he needed the challenge."

What makes good leadership is not a subject that gets much legitimate coverage in the modern world.  As such, it's extremely rare.  But it's an amazingly interesting subject.  There's many different ways of doing it, but they all have a few things in common: *results, and enjoyment*.  If the people working under you aren't happy, that's bad leadership.  If you aren't getting the job done, that's bad leadership.

Personally, *I choose to lead from behind*.  The style is to basically let people go about their own way, approaching problems the way they see fit, while keeping an eye on them whenever they stray from the path you desire, and using minimal force to keep them in-bounds.  I prefer rewards over punishment (as does every psychology study).  In this way, you *maximize your teams personal agency, responsibility, creativity, and happiness*.  Over time, you also minimize the need to micro-manage, which allows for more personal bandwidth.

Please -- if you're interested -- chat with me about this subject.
	`),
});

export default JobEvents;
