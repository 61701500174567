import { ModalManager } from "helium-ui-components";
import { Pointy } from "src/components/Pointy/Pointy";
import { EventFilterState } from "./EventFilterState";
import { RouteState } from "./RouteState";

export class AppState {
	public get easterEgg() {
		this.pointy.show();
		return "Say Hi to Pointy!"
	}

	public readonly eventFilter = new EventFilterState();
	public readonly route = new RouteState();
	public readonly modals = new ModalManager();

	public readonly pointy = new Pointy();
}

export const APP = new AppState();
(window as any).APP = APP;
