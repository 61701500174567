



interface IPoint3 {
	x: number;
	y: number;
	z: number;
}


export class StarAnimationScratch {
	protected numPoints = 100;

	public locs: {
		matrix: WebGLUniformLocation
	} = {} as any;

	protected state: {
		time: number;
		mouse: {
			x: number;
			y: number;
		};
		rotAll: {
			x: number;
			y: number;
		};
		layers: Array<{
			rot: IPoint3;
			dRot: IPoint3;
		}>
	};

	public maxRotSpeed = 15/1000000;

	constructor(protected args: { mousemoveTarget?: HTMLElement } = {}) {

		this.state = {
			time: -1,
			rotAll: { x: 0, y: 0 },
			mouse: { x: 0, y: 0 },
			layers: []
		};
		for (let i = 0; i < 5; i++) {
			this.state.layers.push({
				rot: {
					x: Math.random() * 2 * Math.PI,
					y: Math.random() * 2 * Math.PI,
					z: Math.random() * 2 * Math.PI,
				},
				dRot: {
					x: Math.random(),
					y: Math.random(),
					z: Math.random(),
				}
			})
		}
	}




	protected _canvas: HTMLCanvasElement;
	public get canvas() {
		if (!this._canvas) {
			this._canvas = document.createElement("canvas");

			const mousemoveTarget = this.args.mousemoveTarget || this._canvas;
			mousemoveTarget.addEventListener("mousemove", ({x, y}) => {
				const width = mousemoveTarget.offsetWidth;
				const height = mousemoveTarget.offsetHeight;

				this.state.mouse.x = x - (width/2);
				this.state.mouse.y = y - (height/2);
			});

			mousemoveTarget.addEventListener("mouseleave", () => {
				this.state.mouse.x = this.state.mouse.y = 0;
			});

			const observer = new window.IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					if (this.paused) { this.play(); }
					return
				}
				this.pause();
			}, {
				root: null,
				threshold: 0.21,
			})

			observer.observe(this._canvas);
		}
		return this._canvas;
	}

	protected _gl: WebGLRenderingContext;
	public get gl() {
		if (!this._gl) {
			const { canvas } = this;
			const gl = this._gl = canvas.getContext("webgl", {
				preserveDrawingBuffer: true,
				premultipliedAlpha: false
			});
			if (!gl) {
				console.error("Unable to initialize WebGL2. Your browser or machine may not support it.");
				return;
			}

			gl.clearColor(0.0, 0.0, 0.0, 0.0);
			// gl.clear(gl.COLOR_BUFFER_BIT);
			// gl.clear(gl.DEPTH_BUFFER_BIT);

			this.updateSize();
		}
		return this._gl;
	}

	public updateSize(attempts = 5) {
		if (attempts <= 0) { return; }

		const parent = this.canvas.parentElement;
		if (!parent) {
			return requestAnimationFrame(() => this.updateSize(attempts - 1))
		}

		const { offsetWidth, offsetHeight } = parent;
		if (offsetWidth <= 0|| offsetHeight <= 0) {
			return requestAnimationFrame(() => this.updateSize(attempts - 1))
		}

		this.gl.viewport(
			0, 0,
			this.canvas.width = offsetWidth,
			this.canvas.height = offsetHeight
		);
	}

	protected inited = false;
	public init() {
		if (this.inited) { return; }
		this.inited = true;

		const { gl } = this;
		if (!gl) { return; }


		const vertCode = `
			attribute vec4 a_points;
			attribute vec4 a_color;
			uniform mat4 u_matrix;

			varying vec4 v_color;

			void main(void) {
				gl_Position = a_points * u_matrix;
				gl_PointSize = 1.25;
				v_color = a_color;
			}
		`;
		const vertShader = gl.createShader(gl.VERTEX_SHADER);
		gl.shaderSource(vertShader, vertCode);
		gl.compileShader(vertShader);

		const fragCode = `
			precision mediump float;
			varying vec4 v_color;

			void main(void) {
				// gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
				gl_FragColor = v_color;
			}
		`;
		const fragShader = gl.createShader(gl.FRAGMENT_SHADER);
		gl.shaderSource(fragShader, fragCode);
		gl.compileShader(fragShader);

		const program = gl.createProgram();
		gl.attachShader(program, vertShader);
		gl.attachShader(program, fragShader);
		gl.linkProgram(program);
		gl.useProgram(program);


		const colorAmt = 0.5;
		const points: [number, number, number][] = [];
		const colors: [number, number, number][] = [];
    for (let i = 0; i < this.numPoints; i++) {
			const {sin, cos, random} = Math;
			const dist = 1 - (random() * random());
			const alpha = random() * 2 * Math.PI;
			const polar = random() * 2 * Math.PI;

      points.push([
        dist * sin(polar) * cos(alpha),
				dist * sin(polar) * sin(alpha),
				dist * cos(polar)
			]);

			const hue = random();
			const saturation = random() * 0.3;
			const rgb = HSV_to_RGB(hue, saturation, 1.0);

			colors.push(rgb.map((v) => v * 256) as any)
    }

		this.locs.matrix = gl.getUniformLocation(program, "u_matrix");

		const colorBuffer = gl.createBuffer();
  	gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
	  gl.bufferData(gl.ARRAY_BUFFER, new Uint8Array(colors.flat()), gl.STATIC_DRAW);
		const a_color = gl.getAttribLocation(program, "a_color");
		gl.enableVertexAttribArray(a_color);
		gl.vertexAttribPointer(a_color, 3, gl.UNSIGNED_BYTE, true, 0, 0);


		const geometry = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, geometry);
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(points.flat()), gl.STATIC_DRAW);
		const a_points = gl.getAttribLocation(program, "a_points");
		gl.enableVertexAttribArray(a_points);
		gl.vertexAttribPointer(a_points, 3, gl.FLOAT, false, 0, 0);


		this.updateSize();
	}


	protected _frameReq: any;
	public play() {
		this.paused = false;
		this.init();
		if (this._frameReq) { return; }
		this._frameReq = requestAnimationFrame(() => {
			this.draw();
			this._frameReq = undefined;
			this.play();
		})
	}

	protected paused = false;
	public pause() {
		if (this._frameReq) {
			this.stop();
			this.paused = true;
		}
	}


	public stop() {
		if (this._frameReq) {
			cancelAnimationFrame(this._frameReq);
			this._frameReq = undefined;
		}
	}


	protected draw(): boolean {
		const { gl, state } = this;
		const time = Date.now();
		if (state.time === -1) {
			state.time = time;
			return;
		}

		gl.clear(gl.COLOR_BUFFER_BIT);
		const dt = time - state.time;
		state.time = time;
		state.rotAll.x += state.mouse.y * this.maxRotSpeed/2;
		state.rotAll.y += -state.mouse.x * this.maxRotSpeed/2;

		state.layers.forEach(({rot, dRot}, index) => {
			const x = rot.x + (dt * dRot.x * this.maxRotSpeed);
			const y = rot.y + (dt * dRot.y * this.maxRotSpeed);
			const z = rot.z + (dt * dRot.z * this.maxRotSpeed);
			this.state.layers[index].rot = {
				x, y, z
			}

			const scale = 1.5;
			let matrix = m4.scaling(scale);
			matrix = m4.xRotate(matrix, x);
			matrix = m4.yRotate(matrix, y);
			matrix = m4.zRotate(matrix, z);

			matrix = m4.xRotate(matrix, state.rotAll.x);
			matrix = m4.yRotate(matrix, state.rotAll.y);
			matrix = m4.scale(matrix, 1, 1, 0.5);

			gl.uniformMatrix4fv(this.locs.matrix, false, matrix);

			this.gl.drawArrays(this.gl.POINTS, 0, this.numPoints);
		})

		return true;
	}
}






function HSV_to_RGB(hue: number, saturation: number, value: number) {
	if(hue < 1)
		hue += 1;
	hue %= 1;
	let rgb: [number, number, number];
	if(saturation == 0 || value == 0)
		rgb = [value, value, value];  //used car salesman rick jim here!
	else {
		rgb = [0, 0, 0];
		var oneThird = 1/3;
		var twoThirds = 2/3;
		var rgbPos = []
		rgbPos[0] = ((hue+oneThird)%1)/twoThirds;  //at 0, red pos = 0.5 or peak of sine wave
		rgbPos[1] = hue/twoThirds;  //at .3333, green pos = 0.5 or peak of sine wave
		rgbPos[2] = (hue-oneThird)/twoThirds;  //at .6666, blue pos = 0.5 or peak of sine wave
		for(var i in rgbPos) {
			var pos = rgbPos[i];
			if(pos > 0 && pos < 1)
				rgb[i] = Math.sin(pos*Math.PI) * value;
			else
				rgb[i] = 0;
		}
		if(saturation < 1) {
			var max = Math.max(Math.max(rgb[0], rgb[1]), rgb[2]);
			for(var i in rgb)
				rgb[i] += (max-rgb[i]) * (1 - saturation);
		}
	}
	// for(var i in rgb) {
	// 	rgb[i] = Math.floor(rgb[i] * 256)
	// }
	return rgb;
}




const m4 = {
	// projection(width: number, height: number, depth: number) {
  //   // Note: This matrix flips the Y axis so 0 is at the top.
  //   return [
  //      2 / width, 0, 0, 0,
  //      0, -2 / height, 0, 0,
  //      0, 0, 2 / depth, 0,
  //     -1, 1, 0, 1,
  //   ];
  // },

  // translation(tx: number, ty: number, tz: number) {
  //   return [
  //      1,  0,  0,  0,
  //      0,  1,  0,  0,
  //      0,  0,  1,  0,
  //      tx, ty, tz, 1,
  //   ];
  // },

  xRotation(angleInRadians: number) {
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    return [
      1, 0, 0, 0,
      0, c, s, 0,
      0, -s, c, 0,
      0, 0, 0, 1,
    ];
  },

  yRotation(angleInRadians: number) {
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    return [
      c, 0, -s, 0,
      0, 1, 0, 0,
      s, 0, c, 0,
      0, 0, 0, 1,
    ];
  },

  zRotation(angleInRadians: number) {
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    return [
       c, s, 0, 0,
      -s, c, 0, 0,
       0, 0, 1, 0,
       0, 0, 0, 1,
    ];
  },

	multiply(a: number[], b: number[]) {
    const b00 = b[0 * 4 + 0];
    const b01 = b[0 * 4 + 1];
    const b02 = b[0 * 4 + 2];
    const b03 = b[0 * 4 + 3];
    const b10 = b[1 * 4 + 0];
    const b11 = b[1 * 4 + 1];
    const b12 = b[1 * 4 + 2];
    const b13 = b[1 * 4 + 3];
    const b20 = b[2 * 4 + 0];
    const b21 = b[2 * 4 + 1];
    const b22 = b[2 * 4 + 2];
    const b23 = b[2 * 4 + 3];
    const b30 = b[3 * 4 + 0];
    const b31 = b[3 * 4 + 1];
    const b32 = b[3 * 4 + 2];
    const b33 = b[3 * 4 + 3];
    const a00 = a[0 * 4 + 0];
    const a01 = a[0 * 4 + 1];
    const a02 = a[0 * 4 + 2];
    const a03 = a[0 * 4 + 3];
    const a10 = a[1 * 4 + 0];
    const a11 = a[1 * 4 + 1];
    const a12 = a[1 * 4 + 2];
    const a13 = a[1 * 4 + 3];
    const a20 = a[2 * 4 + 0];
    const a21 = a[2 * 4 + 1];
    const a22 = a[2 * 4 + 2];
    const a23 = a[2 * 4 + 3];
    const a30 = a[3 * 4 + 0];
    const a31 = a[3 * 4 + 1];
    const a32 = a[3 * 4 + 2];
    const a33 = a[3 * 4 + 3];

    return [
      b00 * a00 + b01 * a10 + b02 * a20 + b03 * a30,
      b00 * a01 + b01 * a11 + b02 * a21 + b03 * a31,
      b00 * a02 + b01 * a12 + b02 * a22 + b03 * a32,
      b00 * a03 + b01 * a13 + b02 * a23 + b03 * a33,
      b10 * a00 + b11 * a10 + b12 * a20 + b13 * a30,
      b10 * a01 + b11 * a11 + b12 * a21 + b13 * a31,
      b10 * a02 + b11 * a12 + b12 * a22 + b13 * a32,
      b10 * a03 + b11 * a13 + b12 * a23 + b13 * a33,
      b20 * a00 + b21 * a10 + b22 * a20 + b23 * a30,
      b20 * a01 + b21 * a11 + b22 * a21 + b23 * a31,
      b20 * a02 + b21 * a12 + b22 * a22 + b23 * a32,
      b20 * a03 + b21 * a13 + b22 * a23 + b23 * a33,
      b30 * a00 + b31 * a10 + b32 * a20 + b33 * a30,
      b30 * a01 + b31 * a11 + b32 * a21 + b33 * a31,
      b30 * a02 + b31 * a12 + b32 * a22 + b33 * a32,
      b30 * a03 + b31 * a13 + b32 * a23 + b33 * a33,
    ];
  },

  scaling(sx: number, sy?: number, sz?: number) {
		if (sy === undefined) { sy = sx; }
		if (sz === undefined) { sz = sx; }
    return [
      sx, 0,  0,  0,
      0, sy,  0,  0,
      0,  0, sz,  0,
      0,  0,  0,  1,
    ];
  },

	// translate(m: number[], tx: number, ty: number, tz: number) {
  //   return m4.multiply(m, m4.translation(tx, ty, tz));
  // },

  xRotate(m: number[], angleInRadians: number) {
    return m4.multiply(m, m4.xRotation(angleInRadians));
  },

  yRotate(m: number[], angleInRadians: number) {
    return m4.multiply(m, m4.yRotation(angleInRadians));
  },

  zRotate(m: number[], angleInRadians: number) {
    return m4.multiply(m, m4.zRotation(angleInRadians));
  },

	scale(m: number[], sx: number, sy?: number, sz?: number) {
    return m4.multiply(m, m4.scaling(sx, sy, sz));
  },
};
