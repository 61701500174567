import "./resumeListPage.scss";

import { anchor, br, div, externalLink, img } from "helium-ui";


export function renderResumeListPage() {
	const resumes: Array<{
		name: string;
		path: string;
		imgSrc: string;
	}> = [{
		name: "General Resume",
		path: "/assets/pdf/SephReed-Resume-v2.pdf",
		imgSrc: "/assets/img/300/site/resume preview.webp"
	},{
		name: "Printer Friendly",
		path: "/assets/pdf/SephReed-Resume(print)-v2.pdf",
		imgSrc: "/assets/img/300/site/resume preview (print version).webp"
	}]

	return div("ResumeListPage", [
		br(3),
		div("Info", [
			`Did you know that `,
			externalLink({
				href: "https://en.wikipedia.org/wiki/R%C3%A9sum%C3%A9#History",
				innards: "Leonardo da Vinci is credited with the first resume in 1481"
			}),
			"?"
		]),
		br(),
		div("ResumeList", resumes.map(({name, path, imgSrc}) =>
			externalLink("Resume", {
				href: path,
				innards: [
					div("Name", name),
					div("ImageContainer",
						img({src: imgSrc, attr: { alt: name }})
					),
				]
			})
		)),
		br(5)
	]);
}
