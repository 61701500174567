import { externalLink } from "helium-ui";
import {ILifeEvent, twoColLayout, extLink, fromMD} from "./LifeEvent"


const BuildEvents: ILifeEvent[] = [];


// ------------------------------------------------------------------------------------------------------------------------

BuildEvents.push({
  id: "Cinebox",
  startDate: new Date("May 23 2022"),
  endDate: new Date("Aug 24 2022"),
  title: `Cinebox, Miniature Movie Theater`,
  shortDesc: `A friend from Germany wanted to make a box with videos playing inside.  I built him a tiny movie theater.`,
  images: [
    "/other/cinebox-lineup.jpeg",
  ],
  skills: [
    "Laser Cutting", "2d Art", "3d Modeling", "Video",
    "Fabrication", "Interactive Art", "Collaboration"
  ],
  desc: () => fromMD(`
My German friend (Jon) says that I went overboard, but I'm pretty sure I did the bare minimum.  There's still no wall-paper or sconce lights inside!

The design is pretty basic, a finger-joint laser-cut box with a small amount of diorama details inside and a cell phone screen playing movies.  The only semi-advanced aspect was the use of 3d-modeling bump maps to create a brick texture on the walls, and a bit of painting.  There are currently two iterations of this project floating around: the first which Jon took back to Germany with him, and the second which I made for myself to bring to places in the US.

On the first iteration, the videos being played were about ~20 hours of interesting content that was recommended by friends or otherwise discovered.  The idea was that people would have no idea what was playing inside, and would likely never see it again.

On my second iteration, I've leaned more towards a short list of very specific films.  The requirements were: no plot, amazing visuals, pleasant audio.  Think Fantasia.  I've found this iteration to be a very pleasant surprise for everyone who peers in, temporarily transporting them to the back row of a theater watching a beautiful film.

I'd like to work on making the illusion even more complete, focusing on micro-details until it's convincing enough to have the same "transport to another world" effect that VR often has.
  `),
  links: () => [
    externalLink({
      href: "https://www.youtube.com/watch?v=kLl74ufv8a8",
      innards: "Funny little video with it"
    }),
  ],
});


// ------------------------------------------------------------------------------------------------------------------------

BuildEvents.push({
  id: "HowdyFuckers",
  hasExpletives: true,
  startDate: new Date("Feb 1 2021"),
  endDate: new Date("May 31 2021"),
  title: `"HowdyF*ckers.online" Art Festival`,
  shortDesc: `Inspired by pandemic, a online art festival including multiple interactive-online gathering spaces with the primary focus being a Minecraft world that is built up and then burned down.`,
  images: [
    "Howdy-Fuckers.gif",
  ],
  skills: [
    "Peer Leadership", "Architecture", "Interactive Art",
    "UX", "Helium UI", "Onboarding", "Mentorship",
    "Website", "Collaboration", "Documentation",
    "Sass", "Servers", "Linux", "Audio", "Bitwig",
    "Task Delegation", "TypeScript"
  ],
  desc: () => fromMD(`
This was actually the second iteration of this idea, with the first being put together in only a few days and a couple facebook posts.  The draw is simple: a bunch of friends get together during the time of year they typically would (a community festival), we all build a world inspired by that festival, and then we burn it down.

The first time it was actually a surprise that we were burning down more than just one big build, and everything aligned just perfect for the turn out to be surprisingly large (hitting the cap of 70 players, with plenty more watching the live stream).

So when pandemic went from a one year to a two year thing, another attempt was made.  This time bigger, and better.  There was more time, we made a website to give simple onboarding instructions, we included alternative methods of interacting... we even went so far as to use geo data to generate an impressively accurate version of the festival grounds in Minecraft.

Fortunately for the world, the stars did not align this year, with vaccines becoming widely available in the weeks leading up to the event.  The community was able to meet in person!  Never-the-less, many people did show up and enjoyed the trip down memory lane.

-------

Here's a list of interesting bits there was:

- A Discord server with the soundscape of the event (generators, laughing, wind, overlapping music in the distance) being broadcast into video chat; surprisingly reminiscent.
- A thoroughly modded Minecraft server with a very accurate (for Minecraft) modeling of not only the festival lands, but the various camps and landmarks of it (greeters station, port-o-potties, art, fences, everything we could make)
- A large "Temple of Bonobo" in memorial of an awesome dude named Bonobo who had passed
- An AltSpace VR world was made to represent the dance and music area of the festival
- A service called Topia was used to make a 2d web and video chat exploratory version of the festival: basically, you click to wander around and if you get near someone their video chat bubble pops in.  There was also links to art all over it.
- A relatively interactive website with docs, instructions, links, and whatever else was needed
- A Discord server
- Some video streams and recordings (linked below)
  `),
  links: () => [
    externalLink({
      href: "https://www.youtube.com/watch?v=YzeEAdJdB-E",
      innards: "World tour with commentary"
    }),
    externalLink({
      href: "https://www.youtube.com/watch?v=DusjaMHsmNg",
      innards: "Temple of Bonobo Burn"
    }),
    externalLink({
      href: "https://www.youtube.com/watch?v=IJ_aG_yu_Gg",
      innards: "Pre-burn fly through"
    }),
    externalLink({
      href: "https://www.youtube.com/watch?v=yv9Pcng78LI",
      innards: "Video stream of the Burn"
    })
  ],
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "LoneBar",
  startDate: new Date("Oct 15 2020"),
  endDate: new Date("Jan 21 2020"),
  title: `Lone Bar Beer$$$ 4U`,
  shortDesc: `A silly joke that was taken to a professional level of fabrication: a beer loan agency.`,
  images: [
    "big-builds/lone-bar-crew.jpg",
    "big-builds/lone-bar-desks.jpg",
  ],
  skills: [
    "Interactive Art", "Performance", "UX", "Fabrication",
    "Video", "Audio", "Bitwig", "Collaboration"
  ],
  // links: () => [
  // 	externalLink({
  // 		href: "",
  // 		innards: ""
  // 	})
  // ],
  desc: () => fromMD(`
"Wouldn't it be funny if we made -- like -- a scummy loan agency for beer?"

Nobody knows how it started, but *it was a stupid idea which turned into a big, giant, hilarious, stupid project*.  What a blast!  And over time the project evolved into more than just a joke, but a truly rewarding experience for participants.  People would come up, enjoying the inherently comedic aspect of the piece, we'd spend some time riffing with them, send them on a quest to "improve their beer credit," and then on return we'd give them a warm foamy tasteless lager.

My favorite quest came on the last day of the event, where -- having been given the information of various beer loan holders -- we began sending people out to "serve" them their "beer debts."

------

*An intentionally ludicrous amount of time and energy* was put into building out the visage of a beer loan agency, including:

- A "safety-windowed" desk for interactions
- A tacky waiting area with a tiny (toddler sized) couch
- Background "Muzak" from 80s Kmarts; pitched down slightly to give it a subtle comedic affect.  Store ads were either modified or replaced to keep in line with the aesthetic and storyline.
- A television playing various serious looking satire pieces such as: onion 24 hour news, monster trucks, fake car commercials, etc.
- So many carbon-copy paper forms which had to be filled out (and faxed until the machine broke).  Filing these with one of the "Loan Bar Employees" were the crux of user interaction; my two favorite "Beer Loan Requirements" were: <ul>
- "Did you just lose the game?" -- a reference to a game that is lost by thinking of it, and
- "Has nice eyes?" -- the answer was always yes, and it made people smile.
</ul>
- A book of "quests" to so applicants could improve their "beer credit."  My favorites were:<ul>
- Go find pointy -- a scavenger hunt for our mascot Pointy Mc PointyFace
- Go to "Lets be Frank" -- another art camp that had users share an embarrassing truth as chosen by a wheel spin (then get a hot dog)
</ul>
- Employee dress codes and legit engraved name tags (they say "Beer Loan Professional" on them, and I love mine)
- A legitimate filing system which we used to verify quests that were completed
- A faux buy-out and merger with a local bar.  An actual MIT lawyer got in the way by making a case that we still owed their camp a partnership deal.
- A very large ugly sign which said "This attraction sponsored by Loan Bar" that we bolted into the ground outside of various other art pieces (they had, unknowingly, signed papers which said we could).  To this day, the sign thrives and has taken on a life of its own, getting bolted down all over the place in Austin.
- A wacky wavy inflatable arm tube man, bought specifically for the event.

Just writing about it is enough to get me laughing and smiling.  It was just so completely out of place at an art festival, but -- for participants who'd been around a while -- the joke was "epic."
  `),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "DeathGun",
  startDate: new Date("May 7 2019"),
  endDate: new Date("Aug 10 2019"),
  title: `Death Gun`,
  shortDesc: `A tesla coil shooting gallery; I programmed the game and score board aspects.`,
  images: [
    "Deathgun.gif",
    "big-builds/deathgun-scoreboard.jpg"
  ],
  skills: [
    "Featured ⭐️", "Interactive Art", "UX", "Node JS", "Linux",
    "Micro-controllers", "Collaboration", "Games", "HTML5 Canvas",
    "TypeScript"
  ],
  links: () => [
    externalLink({
      innards: "View Aerica Ravens Portfolio/Gallery",
      href: "https://www.aerica-raven.com/special-projects/deathgun",
    })
  ],
  desc: () => fromMD(`
*Aerica is an amazing muralist; Steve an electrical engineer with a fondness for tesla coils*.  They're a bit of a power couple, and came up with this amazing project.  But they wanted to take it a bit further by making the game more gamey: they needed a programmer.

The first part of the project involved setting up a *Raspberry Pi running Node JS and using one of the USBs as a serial port*.  It took some finagling as it was new territory and there's always a lot of gotchas when working with micro-controllers.

From there the coding was breezy, taking only a few days.  The *scoreboard was a website with a websocket* open to a localhost server.  The same server controls the game and responds to user input.  In regards to game design there was some experimentation: the system ran on asynchronous promises rather than a constant loop.  For testing the game, a small mock up web version of them game was created.

The experience of the game was three levels in a branching path where each one could lead to either a greater or lesser challenge; a total of 6 levels.  *Almost every player was able to complete the game, but only a few made it to secret-ish the hardest level*.

One of my favorite "pranky" aspects of the game was that there were only 6 digits for high scores, and if a player went above that it just rolled over to zero.  *Many laughs were had*, and it set a new challenge: get as close to 999999 as possible without going over.  The other "pranky" part of the scoreboard was that it displayed "most average scores" as well as high-scores.  Players were rewarded with plenty of fanfare for getting a "most average" play-through.
  `),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "Hexapod",
  startDate: new Date("Dec 15 2016"),
  endDate: new Date("Nov 20 2016"),
  title: `Hexapod`,
  shortDesc: `Project rendering for SXSW art grant submission.`,
  images: [
    "big-builds/hexapod.png"
  ],
  skills: [
    "UX", "3d Modeling", "Blender",
    "Website", "Collaboration"
  ],
  links: () => [
    externalLink({
      innards: "Project Proposal",
      href: "https://sephreed.github.io/Hexapod/",
    })
  ],
  desc: () => fromMD(`
After being impressed with a piece Misty and I worked on called "The Vessel," a couple friends asked if we wanted to collab for SXSW art grant.  So we all got together and ideated about what we could make within the scope of the grant.  Eventually the "Hexapod" is what we decided on: a neat looking 3d geometric shape with mirrors on the inside; a bit trite, a bit selfie bait, but also pretty cool.

We only ever made it to submitting a proposal, but part of it included a 3d model and website -- the model in particular being a new skill for me.  I used SketchUp to draw the shape, and then Blender to add materials and render it.
  `),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "Cononagon",
  startDate: new Date("Jan 1 2018"),
  endDate: new Date("Apr 10 2018"),
  title: `PINE Cononagon`,
  shortDesc: `Co-designer, Lead Engineer, and Foreman for a large-scale community-built multi-media interactive art piece about life and death.  Delivered early and under-budget, without any volunteer burn-out.`,
  images: [
    "big-builds/Cononagon_Glamor.jpg",
    "big-builds/Cononagon-Forest_entrance.jpg",
    "big-builds/Cononagon_underworld(sax).jpg",
    "big-builds/Cononagon_engulfed.jpg",
  ],
  skills: [
    "Featured ⭐️", "Peer Leadership", "Structural Eng.",
    "Architecture", "Fabrication", "3d Modeling",
    "Interactive Art", "UX", "Grit",
    "Collaboration", "Roadmapping",
    "Task Delegation", "Volunteer", "Onboarding",
    "Mentorship", "Website", "SketchUp"
  ],
  links: () => [
    externalLink({
      innards: "Project Proposal Website",
      href: "https://mistynickle.github.io/DaFT-2018/proposal.html",
    })
  ],
  desc: () => fromMD(`
This project was the most involved I've ever led.  It was *a large-scale multimedia endeavor, spanning three floors, brimming with creativity*, and built entirely by volunteers (myself included) on a shoe-string materials-only budget.

The outside of the piece was meant to look like a pine cone and had a nine-sided (nonagon) footprint.  Interior, *there were three floors and a secret room*.  The second floor was the main entrance; a twinkling forest with an 8ft wide CNC-etched-bark tree in the center and hand-made vines woven around.  An arched cove in the tree led to a spiral staircase which wound down from the mortal realm forest into the after-life underworld; a cozy den filled with community art pieces dedicated to lost loved ones.

The *underworld represented the idea of immortality through lore and stories*; it also housed a live acting group which sent participants on quests related to helping citizens of the afterlife.  The *top-most floor was hidden as it represented immortality through science* and was decorated as a comfy padded stratosphere staring upwards into fiber-optic constellations spread across the ceiling.

Once *lit on fire, the petals which covered the pine cone opened up* using springs and burnable restrains, a homage to how pine cones reproduce.  The last reveal was hidden in the middle of it all, a metal tree which remained standing after everything else had burned down.

Here are the parts I can claim responsibility for:


- Leadership<ul>
- Always having next steps planned and diagramed
- Showing people easier ways to do things (being *mindful not to ruin the puzzle* if they were still self-teaching)
- Exemplifying safety and always doing things in ways others could understand
- Being *thankful and appreciative* of others
- Keeping an eye out for the safety of others
- Making sure to not let people overwork themselves
</ul>


- Foreman and Engineer<ul>
- Create, maintain, and reference 3d CAD model
- Assurement and testing of sturdiness/strength
- Planning out and *testing build tasks (materials, tools, techniques) before assigning them*
- Helping with build tasks when I had the time
- Knowing everything currently being worked on, checking in frequently (and congratulating often)
- Coming early/staying late to do all the worst work that would be cruel to assign
</ul>


- Co-Designer<ul>
- Came up with thematic inspirations for each of the areas, and wove a story across them
- Decided upon artistic boundaries for the art pieces, soundscape, and live actors
- Came up with lore and backstories for the piece
- Created the overall canvas which the volunteers filled
</ul>

- Developer<ul>
- Wrote the software for the LEDs
- Made the proposal website
</ul>



<small>*We managed to complete this project in record time (the event has been running for 20 years) while training newbies, keeping well within budget, without any drama*.  I'd long since felt capable of leadership roles, but this level of success indicates something beyond being simply "capable".  That said, I could not have done this without Misty who is an amazing Product/Project Manager, HR, UX Designer, and Creative Director.  Nor could either of us have done it without all the build crews, volunteers, and organizers who came before us and paved the way.</small>
`),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "PlayaMet",
  startDate: new Date("Aug 1 2016"),
  endDate: new Date("Dec 17 2016"),
  title: `Playa Met`,
  shortDesc: `Public transportation project proposal for Burning Man, backed by user research and art car buy-in.`,
  images: [
    "other/playa-met-logo.png",
    "other/playa_met_render_ideal.png",
  ],
  skills: ["Blender", "Website", "3d Modeling", "SketchUp", "UX"],
  links: () => [
    externalLink({
      innards: "Project Proposal",
      href: "https://sephreed.github.io/Playa_Met/",
    })
  ],
  desc: () => fromMD(`
A lot has changed from when Misty and I first started going to Burning Man.  In particular, it feels like we haven't gotten on an art car in years.  What's up with that?  And *wouldn't it be cool if art cars were the cities public transportation?*

*This project started out where any decent project should: research.*  We spent a solid afternoon in the DMV line talking to people in line to register their art car.  We asked them about their experiences picking people up, the good and the bad.  We asked people about their inspirations, just out of interest.  And lastly, we asked them: "If there were 4 bus stops, and you only ever had to take people from one to the next while in that direction, with no schedule, would you?"

Of course most people say yes to such a question, but it seemed earnest.  Some drivers stated they'd do it all the time.  They'd stay up doing it.  Overall, *the idea was a big success with drivers*.  But we also heard over and over again: isn't there already x bus system?

As it turned out, there was a bus system that someone with quite a bit of clout was trying to start.  It was terribly designed; we thought so, and the drivers thought so too.  It was strictly scheduled with too many stops and.. just a bad idea.

So Misty and I put in a proposal the next year.  It included various quotes from our user research, a custom website, and a fancy 3d render of what our bus stops would look like.  *Our proposal was denied, so we brought 500 vuvuzelas instead*.  We intend to make this project happen eventually, as well as bring even more vuvuzelas.
  `),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "PlaywoodPalace",
  startDate: new Date("Jan 1 2017"),
  endDate: new Date("Apr 10 2017"),
  title: `Playwood Palace`,
  shortDesc: `Codesigner, Lead Engineer, and Foreman for a large-scale art project based on my childhood castle-playground.`,
  images: [
    "big-builds/2017_by_Mystic%20Loden_big.png",
    "big-builds/inner_adults.png",
    "big-builds/dustin_boosh.png",
    "big-builds/collapse.png",
  ],
  links: () => [
    externalLink({
      innards: "This site has tons of links to media from the project",
      href: "http://sephreed.github.io/Playwood_Palace/index.html",
    }),
    externalLink({
      innards: "Project Proposal",
      href: "https://sephreed.github.io/DaFT_2017/"
    }),
    externalLink({
      innards: "Step by step build book",
      href: "https://sephreed.github.io/DaFT_2017/Docs/buildBook.html"
    }),
  ],
  skills: [
    "Featured ⭐️", "Peer Leadership", "Structural Eng.",
    "Architecture", "Fabrication", "3d Modeling",
    "Interactive Art", "UX", "Grit",
    "Collaboration", "Roadmapping",
    "Task Delegation", "Volunteer", "Onboarding",
    "Mentorship", "Website", "SketchUp"
  ],
  desc: () => fromMD(`
Playwood Palace was *a three story maze and castle-playground shaped vaguely like a unicorn*, built by a volunteer team for the regional Burning Man-inspired event: Flipside.

The winding interior of the first floor consisted of the *cafe/bar, a hobbit home, a mini forest with fake campfire, a greek veranda with blacklight chalkboards, and a dark-room ballpit in space*.  Connecting the first and second floor were 1ft tall steps which, alongside the 4ft tall railings, gave participants the perception of being small again.

On the second floor were *two secret rooms*, one of which was full of treasure and required two people working together to open (the door had a pull string latch with the pulling end hidden far from the door).  From the second floor, participants could get into both of *the turrets, each containing an aimable flamethrower*.  On the third floor was the crux of the piece, the top of *a three-story slide*.  Tons of work and volunteer hours went into this piece -- this is what of it I can claim:

- Leadership<ul>
- Always being multiple steps ahead on what needs to be done
- Showing people easier ways to do things (being mindful not to puzzle ruin)
- Exemplifying safety and always doing things in ways others could understand
- Being thankful and appreciative of others
- Keeping an eye out for the safety of others
</ul>

- Foreman and Engineer<ul>
- Create, maintain, and reference 3d CAD model
- Assurement and testing of sturdiness/strength
- Planning out and testing build tasks (materials, tools, techniques) before assigning them
- Spending ~30 hours a week fabricating the more challenging projects
- Knowing everything currently being worked on, checking in frequently (and congratulating often)
</ul>

- Co-Designer<ul>
- Came up with thematic inspirations for each of the areas
- Created the soundcape
- Co-authored the story in the "childrens" book made for the piece
- Designed the t-shirts, as well as colorizing/photoshopping images for our swag and the "childrens" book
- Created the overall canvas which the volunteers filled
</ul>

- Developer<ul>
- Made the proposal website
- Created a budgeting tool which calculated the total price of the piece, as well as the materials list
- Created a media page
</ul>
`),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "TheVessel",
  startDate: new Date("Sep 12 2016"),
  endDate: new Date("Nov 20 2016"),
  title: `The Vessel`,
  shortDesc: `An exploratory interactive art piece.  Our addition was a secret space tunnel, behind a painting of space.  Additionally, there was a secret room inside and a slide into ball-pit at the end.`,
  images: [
    "big-builds/The-Vessel-Layout.png",
    "The-Vessel-Climb-Into-Space.gif",
    "big-builds/The-Vessel-Space.jpeg",
    "big-builds/The-Vessel-Escape-Pod.png",
  ],
  skills: ["Interactive Art", "UX", "Fabrication", "Website", "Collaboration"],
  // links: () => [
  // 	externalLink({
  // 		innards: "Project Proposal",
  // 		href: "https://mistynickle.github.io/DaFT-2018/proposal.html",
  // 	})
  // ],
  desc: () => fromMD(`
The "Space Crawl" was a section of an art piece called "The Vessel" which was an interactive/exploratory building. The space crawl was usually one of the last rooms to be found as it was a small crawl space hidden behind a painting of space. The inside of the room was lit with black light and painted to look like outer space, planets, and stars and played a 15 minute ambient song I made on loop. At the end of space crawl was a slide that went into a ballpit, and hidden behind one of the walls was yet another secret passageway to a room called "The Escape Pod." It was designed and built by Misty Nickle and I.`
  ),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "BabyFingers",
  startDate: new Date("Mar 12 2016"),
  endDate: new Date("Jun 1 2016"),
  title: `Baby Fingers`,
  shortDesc: `An interactive music project which used tonal theory to transform the haphazard interactions of users into a ever changing soundscape.`,
  images: [
    "big-builds/flipside_baby.png",
  ],
  skills: ["Audio", "Python", "Micro-controllers", "Interactive Art", "UX", "Linux", "Micro-controllers", "Jazz Theory", "Procedural Gen."],
  // links: () => [
  // 	externalLink({
  // 		innards: "Project Proposal",
  // 		href: "https://mistynickle.github.io/DaFT-2018/proposal.html",
  // 	})
  // ],
  desc: () => fromMD(`
A good friend was building an "Effigy" (large wooden structure to be burned), and wanted an interactive "baby music" element to it.  The idea was that the fingers would have paddles as fingernails that would play sounds.  He wanted a sort of xylophone/music box sound to it.  I ended up taking it a step further.

The final design did play xylophone sounds, but the tones were algorithmically decided to take what would have been an obnoxious and repetitive ambience and turn it into a constantly evolving one.  The basic gist of the program was as follows:

- Every 30 seconds, choose a new key from major, minor, or harmonic minor (rare)
- Every 10 seconds, choose a random chord from the key
- Every 5 seconds, choose 8 random notes from a stacking of 3 octaves worth of the current chord, then map those in order to each finger

Essentially, every 5 seconds the notes would move some small amount to either accentuate the same chord a bit differently, or move to a new chord or key entirely.  There was also one last rule:

- If ever the baby isn't played with for over an hour, start a never ending loop of wailing

This last rule led to some magnificent prank moments, only ever happening twice over the 4 days; both times around 6am.  Other than that, people were playing with it constantly, and it sounded great!
  `),
});


// ------------------------------------------------------------------------------------------------------------------------


BuildEvents.push({
  id: "FEG-FlameSequencer",
  startDate: new Date("Mar 4 2015"),
  endDate: new Date("Aug 27 2015"),
  title: `Effigy Flame Effects Sequencer`,
  shortDesc: `Tool for producing and simulating flame effect patterns.`,
  images: [
    "wizard_effigy_loop.gif"
  ],
  skills: ["Java"],
  links: () => [
    externalLink({
      innards: "Video of the sequencer controlling leds",
      href: "https://www.youtube.com/watch?v=28CqNBAPzF0",
    }),
    externalLink({
      innards: "A full tour of the program",
      href: "https://www.youtube.com/watch?v=GQMoaTI1MQE",
    }),
  ],
  desc: () => fromMD(`
This was my introduction to an Austin community I came to love very dearly: the Flipsiders.  The very first project I stumbled into was a four story wizard head, brimming with fun-house style creativity.  And out front of it was a pair of hands, facing up towards the sky, with flame-throwers in each finger and palm.

They needed a tech crew to sequence these flame effects, so I took on the role of making the sequences.  I was young and excited, so I made an entire Java program for it... with an embarrassing amount of custom UI elements.  Many of them were pulled from early projects making sequencers, but still.

The way the program worked was very similar to any other sequencer (seriously, I could have just used a simple midi program), the only major feature being that it showed a simulation of what it might look like and had special tools for generating patterns.  It even had its own proprietary file extension for saves, a .feg (eff-i-gy).

In the end, extreme weather conditions (25ft flooding) made the event into a bit of a nightmare for art projects, and the sequences never really saw the light of day.
  `),
});




export default BuildEvents;
