import {div, el, externalLink} from "helium-ui";
import {ILifeEvent, twoColLayout, extLink, fromMD} from "./LifeEvent"


const CodeEventList: ILifeEvent[] = [];



https://sephreed.itch.io/rolling-ferns


CodeEventList.push({
	id: "Rolling Ferns",
	startDate: new Date("July 18 2022"),
	// endDate: new Date("Feb 20 2022"),
	title: `Rolling Ferns`,
	skills: [
		"Featured ⭐️", "Games", "Low-Level Web", "Helium UI", "TypeScript", "UX", "2d Art", 
		"Jazz Theory", 
	],
	shortDesc: `Another game jam submission.  A calming numbers game, made with Helium.  Had multiplayer too.`,
	images: [
		"hobby-code/rolling-ferns-title.png",
		"hobby-code/rolling-ferns-gameplay.png",
	],
	links: () => [
		externalLink({
			innards: "Play the game",
			href: `https://sephreed.itch.io/rolling-ferns`,
		}),
		externalLink({
			innards: "Watch a let's play and interview",
			href: `https://www.twitch.tv/videos/1537491520`,
		}),
	],
	desc: () => fromMD(`
		The theme for this year was "Roll the Dice."  The game involves rolling a bunch of dice, and selecting the ones that either add up to *or* multiply to a card.  There's some very calming music and no time limit, the idea being you're trying to think ahead and use as few dice as possible.

		I also made a multiplayer mode.  It was quite frantic, but also very fun.

		The music I made and the sound effects are my favorite part.
	`)
});


CodeEventList.push({
	id: "Helium",
	startDate: new Date("Jun 17 2019"),
	endDate: new Date("Feb 20 2022"),
	title: `Helium-UI`,
	skills: [
		"Featured ⭐️", "Low-Level Web", "TypeScript", "UX", "Documentation",
		"Helium UI", "Sketch App", "Website"
	],
	shortDesc: `The tool used to make this portfolio.  Similar to React or Vue, but faster, lighter, and more versatile.  Still in private alpha.`,
	images: [
		"helium-ui-logo.gif",
		"hobby-code/HeliumUI-DerivationFlowChart.png",
	],
	desc: () => fromMD(`
Jeeze.. where to even begin?  This project started with **"El-Tool"** in mid 2018.  It was just a small ~200 line tool for taking a bunch of repetitive \`document.createElement()\` code and making it easier to read.  In the end, *I liked El-Tool more than React for most use cases*, even if it didn't have states.

The next phase involved "observables," little state-like variables which had callbacks for whenever they were updated.  I used these extensively in a Native-Script project, building a native mobile app.  But observables got very tiresome at scale; they didn't chain well and often created callback hells.  I needed something better, and was blessed with a Eureka moment.

**Helium** is was arose from that.  It was a pretty tiny project at first, and still is quite small (<400kb unminified with maps and definition files).  But it's also definitely grown.  At this point *it's the only tool I use for web development*, and it's magnificent!  Having a couple of the right ideas together (I call it Source-Derivation) makes it so it's not only *more malleable* than any framework I'd used before, it's also *smaller* and *more powerful*... or at least that's what the benchmarks show.

Note: that compared to serving HTML, it is a bit slower: browsers multi-thread html like crazy, but multi-threading JS isn't so easy.  This drawback effects all JS based tools.

Sometime around 2020 I broke the project into two halves: \`helium-sdx\` and \`helium-ui\`.  The UI part extends sdx (Source Derivation) by adding a bunch of web specific stuff.  And as of 2022 it's pretty much ready for release.  *I've been using it in production for years on every project I do and rarely find things I want to add to it anymore*.  But I don't feel like dedicating my life to it, and competing with Facebook seems like a nightmare so who knows if or when it might be released.

-----------

There's also a very useful sub-project for this I've been working on for a while called \`helium-source-repo\`.  It's a tool that automatically manages pulling data objects from APIs, creating class versions of those objects, and serving them in a state-based source-derivation manner.  It's hard to explain without showing, but it makes it really easy to get ahold of data, know if you should show a loading bar, edit the data, and push it back -- all without ever having to interact with any API directly or write a single callback.
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "eImprov",
	startDate: new Date("Oct 3 2016"),
	endDate: new Date("Feb 21 2022"),
	images: [
		// "hobby-code/QuneoImprov.png",
		"hobby-code/QuneoDrumMode.png",
		"hobby-code/QuneoClipMode.png",
		"SephLive-AvantGarde.mov"
	],
	title: "eImprov",
	shortDesc: `Controller scripting framework for live music improvisation via Bitwig`,
	skills: [
		"Featured ⭐️", "Performance", "UX", "TypeScript", "Audio",
		"APIs", "Bitwig"
	],
	desc: () => fromMD(`
Started in 2016 and only just recently used for live improvised performance, eImprov is a secret passion of mine.  It's a framework specifically for Bitwigs Controller Scripting API that extends Bitwig functionality to make it *a dream for live performance*.

In a nutshell, it simplifies controller mapping and yields new functionality around *starting/stopping recordings, queue stacking them, controlling parameters on selected tracks*, and whatever else I need.

By far, *the hardest part of this project was the interaction design*.  It's taken a lot of time and practice to figure out what really feels right in the groove.
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "TrafficJamNascar",
	startDate: new Date("Jun 11 2021"),
	endDate: new Date("Jun 13 2021"),
	title: "Traffic Jam Nascar (48hr Game Jam)",
	shortDesc: `The theme of the "GMTK 2021 Game Jam" was "stuck together."  Two friends and I made a game about aggressive break checking in traffic.`,
	skills: [
		"Games", "Vanilla JS", "HTML5 Canvas", "UX",
		"Sketch App", "Collaboration", "TypeScript",
		"Peer Leadership", "Task Delegation"
	],
	links: () => [
		externalLink({
			innards: "Play the game",
			href: `https://sephreed.itch.io/traffic-jam-nascar`,
		})
	],
	images: [
		"hobby-code/traffic-jam-nascar-logo.png",
		"Traffic-Jam-Nascar.gif"
	],
	desc: () => fromMD(`
Created from total scratch (no framework) in less than 48 hours, the gist is: *accelerate and brake in a circle trying to cause collisions behind you*.  There's a link below, if you want to see it in action.

The coding of the controls and visuals were pretty easy, with most of the difficulty coming from getting the acceleration and braking curves just right.  *The hard part was the AI.*  They do pretty decently, but balancing aggressiveness and risk tolerance made for a fun and interesting challenge.  I even got to watch someone do a play-through and enjoy it!
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "SoberMedia",
	startDate: new Date("Oct 15 2020"),
	endDate: new Date("Mar 3 2021"),
	title: "Sober Media",
	shortDesc: `A chrome extension for selectively blurring coercive social media content.`,
	skills: ["Web Extensions", "TypeScript", "Event Systems", "Helium UI", "UX", "Sketch App", "Sass"],
	images: [
		"hobby-code/sober-media-youtube.jpg"
	],
	desc: () => fromMD(`
*Currently defunct.*

This project was inspired by a philosophy around *AI driven personalized feeds: they're about as fair as playing a game of "identity chess" against the grand master*.  Plausibly, future generations shall look back on our laissez-faire attitudes around these algorithms the same way we currently look at the lax public health and sanitation rules of the dark ages:  *"Such an obvious vector for diseases, what did they expect!?"*

Anyways, the gist of this extension is that it *blurs out anything and everything related to user coercion*, and if you want to unblur something just click.  Or -- if you don't want something blurred in the first place -- go to the settings and disable that feature.  Pretty simple, but it adds *a layer of user consent where one had not previously been*.

As of today, it doesn't appear to be working anymore, which is fine.  It never gained popularity, and keeping it up-to-date with the various ui-element selection logic of different social media sites is an endless endeavor.

-------

The extensions \`background.js\` was built using \`helium-sdx\` as the state manager with live updates for every user interaction (no page refresh required).  The extension popup's UI was built with \`helium-ui\`.  The background script communicated with an injected front-end script to decide which elements should be selected and have "blurred" css classes added, as well as event listeners for un-bluring.  *It was a relatively simple and short project*.
	`),
	links: () => [
		externalLink({
			innards: "See/download it in the Chrome Web Store",
			href: "https://chrome.google.com/webstore/detail/sober-media/hfjcloaonnidjodddmgehpinpdfndnbb",
		})
	]
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "rCommunity",
	startDate: new Date("Jun 14 2020"),
	endDate: new Date("Aug 28 2020"),
	title: `rCommunity`,
	shortDesc: `A web platform for creating micro-economies through friends and trust-networks.  Similar to FB Marketplace, but focused on ad-hoc businesses and resource sharing.`,
	skills: ["Web App", "Helium UI", "TypeScript", "UX", "MapBox", "Sketch App", "APIs", "Sass", "Servers"],
	images: [
		"rCommunity.gif"
	],
	desc: () => fromMD(`
When [pixel wealth](https://mkorostoff.github.io/1-pixel-wealth/?v=3) came out, it felt like a call-to-action.  So much of Misty and mine's income was going straight to Amazon; *what if we could buy, trade, and gift with locals instead?*

So I finally started working on rCommunity... again.  *The first time I tried making this app was actually back in 2012*, but I didn't have the chops back then.  The premise was pretty simple, *users create community trust networks and then advertise goods and services within them*.  There's no exchange of money through the app itself (we had alternate financial models in mind), and no legal requirements for users to post within their groups.

The thing that really made this project special compared to normal online shops -- including Etsy or FB Marketplace Groups -- was the *actual community centered focus*.  By putting community first, friends could *share resources and better circulate their micro-economies*.  Also, I have a really dope categorization system which *uses a 20 questions approach to make perusing goods and services fun*.

----

The project was going really well, but I ended up getting distracted when a friend said he was starting a company and needed a CTO.  I believed in the company mission, and rCommunity only needed slight modifications to fit the bill!  It was just too serendipitous to turn down.

I do intend to start this project up again someday, but not without a fair chunk of change to really finance it.
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "ClockOut",
	startDate: new Date("Sep 15 2019"),
	title: "Clock Out (48hr Game Jam)",
	shortDesc: `The theme of "GMTK 2020 Game Jam" was "Only One."  This game let users "hack" multiple game-engine state variables a single time each to solve puzzles.`,
	skills: ["Games", "Helium UI", "TypeScript", "Collaboration", "Sass", "Peer Leadership"],
	links: () => [
		externalLink({
			innards: "Play the game",
			href: `http://sephreed.me/clock-out/`,
		})
	],
	images: [
		"Clock-Out.gif"
	],
	desc: () => fromMD(`
Made for the first ever GMTK Game Jam, this was also the first game I'd worked on since I was a child.  The theme was "Only One," and *I really wanted to test out Helium UI's **source derivation** algorithm*.  So -- using helium -- I coded a puzzle game entirely out of \`HTMLElements\`, where you "hacked" state variables to get to the goal.

This was a brash decision to have made, as *it yielded SO MANY BUGS*.  But that was kind of the point: I made something *much more demanding than a website* and found the points of failure.  At this point \`helium-sdx\` was just a baby, yet it was still possible to make a game with it -- a game that React simply wouldn't work for.

The game was submitted with no time to spare; it was uploaded as a compressed folder and nobody except my friends played it.
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "JustTunings",
	startDate: new Date("Feb 14 2020"),
	endDate: new Date("Mar 5 2020"),
	title: `JustTunings.info`,
	shortDesc: `A website for exploring micro-tonal music theory, with built in keyboard synth.`,
	skills: [
		"Website", "HTML5 Canvas", "Audio",
		"Helium UI", "Synths", "Sass",
		"TypeScript", "Web Audio API"
	],
	links: () => [
		externalLink({
			innards: "JustTunings.info",
			href: `http://just-tunings.info/learn`,
		})
	],
	images: [
		"hobby-code/just-tunings.png"
	],
	desc: () => fromMD(`
This project started out as a way to *visualize a micro-tonal music theory*.  In particular a theory that one of the mathematic aspects of good musicality is having complex relationships that resolve well; like a major triad where \`4/3 * 5/4 = 3/2\`.

The first iteration of this program was just something that brute forces different combinations of a list of simple ratios.  In order to have some bearing of where they were on a piano, \`HTMLCanvas\` was used to draw their "western approximation".  Then, *in order to hear them, a synth was added*.

It was good enough to share, so I eventually made a public website for it (linked below) and added some information on the general "just intoned" music theory.

Since starting this project, **Bitwig** has added micro-pitch features which allow for experimentation with these alternative scales.  Unfortunately -- as I'm not the first to find out -- *most of what people find "pleasant" in music is rooted in familiarity*.  But it's still fun!
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "Portfolio2018",
	startDate: new Date("Oct 3 2018"),
	title: "2018 Portfolio",
	shortDesc: `Previous portfolio, utilizing an early prototype of my web framework "Helium-UI"`,
	skills: [
		"TypeScript", "Sass", "Sketch App",
		"Vanilla JS", "HTML5 Canvas", "Website"
	],
	images: [
		"Portfolio-2018.gif"
	],
	links: () => [
		externalLink({
			innards: "PDF version of the portfolio",
			href: "http://sephreed.me/SephReed-Portfolio.pdf",
		})
	],
	desc: () => [
		fromMD(`
The styling for my second portfolio was *based on a design session at Coder Inc where two others and myself were asked to redo the entire sites aesthetic*.  We wanted high contrast, dark theme, with slender lines and hard edges.  And indeed, it does come off pretty professional.

But there a few others things being experimented with here.
- Firstly: \'el-tool\' (a precursor to Helium UI) was getting it's first real run,
- secondly: a grid-locked scaling system was created for UI elements, and
- thirdly: chamfered borders (not a 90 degree angle or a curve).

If you check out the old site, you'll see that there's a grid in the background which sort of glows wherever the cursor goes.  And you'll also see that *all of the text and borders are aligned to the grid*.  Pretty neat!

The chamfered borders were a major hassle, having to be first drawn by an \`HTMLCanvas\`, then exported to an image, then set to the border image of the element.  Surprisingly, the hardware load was negligible for this.

I never intended to make a new portfolio, in fact this one started as merely an update...
`
		),
	],
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "Synergy",
	startDate: new Date("Oct 2 2018"),
	endDate: new Date("Jan 16 2019 "),
	title: `Synergy`,
	shortDesc: `A tongue-in-cheek resource-management board game based on start-up culture.`,
	skills: ["Games", "2d Art", "Sketch App", "Sass", "TypeScript", "Collaboration", "RegEx"],
	images: [
		"/other/synergy-characters.png",
		"/other/synergy-events.png",
		"/other/synergy-jobs.png",
		"/other/synergy-irl.jpg",
	],
	desc: () => fromMD(`
Inspired by a copious amount of love for board gaming -- then mixed with the woes of job hunting and start-up culture -- Synergy was a fun and comedic respite for Misty and I.  The process *started off with watching "Office Space" on loop*, trying to catch that humorous immortalization of tech life.  But as time went on, the game become something more.

The basic gameplay has gone through various stages, with more to come.  But the gist is: every player is a co-founder, you hire employees, upon hiring you pull quirk cards, events make the quirks come alive, you've got long term goals and short term tasks, and *Synergy represents that ever fleeting resource: employee happiness*.  We'll see how much of this changes in the next iterations.

The characters were made in Sketch App, as well as the iconography and illustrations.  But *the crux of this project was definitely rendering the cards*. Every single part of every type of card was defined in the form of a spreadsheet.  These spreadsheets were then parsed and rendered in-browser to look like something out of Adobe Illustrator.  And from there it's printable.  This was a great design process as *having everything be generative from spreadsheets allowed for super fast iterations*.

------

We did a little bit of "blind" (no input) play-testing with our friends, cut short by me getting a new full time job.  There were definitely some flaws; most notably "hierarchies" kept emerging amongst players that led to one of them being the boss over the others.  The other problems revealed were also pretty similar to real jobs.  lol.

Can't wait to start this one up again later!
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "TraditionChurner",
	startDate: new Date("Dec 18 2018 "),
	endDate: new Date("Mar 7 2019"),
	title: `Tradition Churner`,
	shortDesc: `A "Virtual Synth" VST which allowed me to finally hear a wave function I'd long dreamed of.`,
	skills: [
		"C++", "Audio", "Sketch App",
		"APIs", "Event Systems", "Signal Processing",
		"Synths"
	],
	images: [
		"hobby-code/tradition-churner-design.png",
		"Tradition-Churner.gif",
	],
	desc: () => fromMD(`
The idea for this synth first came to me around 2008.  It was a decade later before I had the skills to make it.

The premise of this synth is that *it's possible to seamlessly shape a wave into the four standard forms* (sine, saw, square, triangle) using only two parameters.  These parameters are the curviness and skew.  You may be able to see what I mean in the animation above.

But just imagining the visual was not enough, it had to be heard.  Alas, *this project was hard*.  I leveraged the **JUCE** audio framework's solid tool-set as much as possible, but there was still tons of highly specific Digital Signal Processing (DSP) challenges.  Notably, creating the *perfect formulas for the curves* (linked below), and trying to implement an asynchronous multi-threaded "observables" system in C++ (*generics and mutexs* oh my!) were big wins.  But the truly hard part was the routing system!

Any signal could be used to modulate any parameter on a per voice, per wave, or synth-wide basis.  This introduced *a ton of performance complexity around how to minimize buffer usage*.  Treating every signal to the "per-voice" solution would have wasted tons of resources.  I mostly remember feeling more exhausted than I have with any other project.

---------

This synth was never fully completed (art, right?), but it did get far enough to sound awesome!  Since then **BitWig** has come out with their own modular synth system which is enough to generally recreate TraditionChurner.  Though not as performant (sans caching), it's pretty cool to be living in the future.
	`),
	links: () => [
		externalLink({
			href: "https://www.desmos.com/calculator/ac2i4rpsb4",
			innards: "Formula for tweening a sine curve to a square or line",
		}),
		externalLink({
			href: "https://www.desmos.com/calculator/zmhrbxvlju",
			innards: "Output formula for curve knob",
		})
	]
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "PINEPortfolio",
	startDate: new Date("Dec 29 2015"),
	endDate: new Date("Jan 15 2018"),
	// tags: ["featured", "development", "design", "imagery", "writing", "3d", "interactive"],
	title: "2015 Portfolio",
	shortDesc: `My first portfolio, using my first custom (Vue-like) web framework "PINE" and some 3d animations.`,
	skills: ["Vanilla JS", "Three JS", "HTML5 Canvas", "Website"],
	links: () => [
		externalLink({
			href: "http://sephreed.github.io/portfolio/index.html",
			innards: "Check out my github.io portfolio",
		})
	],
	images: [
		"hobby-code/PortfolioScreen-2014.png"
	],
	desc: () => fromMD(`
Even though I'd been programming since age 10, and majored in it in college, at age 24 I'd still never worked a tech job.  No regrets there, I was adventuring and being young!  But *in 2014 I decided to maybe try out a bigger paycheck, and this was my first portfolio*.

It was built on my first web framework (called PINE), and had quite a bit of interactive qualities.  In 2018 I made a new portfolio that was "more professional", but now (in 2022) I'm returning to my roots.  This portfolio before you is very much inspired by my very first one.
	`)
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "Docurizer",
	startDate: new Date("Oct 20 2017"),
	title: "Docurizer",
	skills: ["Node JS", "Syntax Parsing", "C++", "RegEx", "Documentation"],
	images: ["Docurizer_overview.gif"],
	shortDesc: `A documentation generator built to dissect an open-source C++ project that had no docs.`,
	links: () => [
		externalLink({
			href: "http://sephreed.github.io/portfolio/Docurizer_Render/index.html?file=main.cpp.html&ov=inc",
			innards: "View Docurizers rendered output (try inspecting elements)",
		}),
		externalLink({
			href: "https://github.com/SephReed/Servers/blob/5e7984405e47587f234679b8bc0e114824e7da13/Docurizer/libs/Scoperizer/scope_defs/cpp/scopes.js",
			innards: "View C++ grammar for Scopifier",
		}),
		externalLink({
			href: "https://github.com/SephReed/Servers/tree/master/Docurizer",
			innards: "View project source code",
		})
	],
	desc: () =>
		fromMD(`
In order *to make sense out of an undocumented open source C++ project*, I built these two tools to parse the project and *automatically generate docs based off any comments it could find laying around*.  A year later, I discovered that when I created Scopifier I'd inadvertently reinvented grammars.  As for Docurizer, it takes a symbolic tree generated by applying a grammar, reorganizes the information, then renders it as html.`
	),
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "FlatWorld",
	startDate: new Date("Jul 3, 2017"),
	endDate: new Date("Sep 14, 2017"),
	title: "FLAT.World",
	skills: ["Web App", "Node JS", "Servers", "HTML5 Canvas"],
	images: ["flatWorld_loop.gif"],
	links: () => [
		externalLink({
			href: "http://sephreed.github.io/FlatStory/index.html",
			innards: "Play with live demo (no server access)",
		}),
		externalLink({
			innards: "View source code",
			href: "https://github.com/SephReed/SephReed.github.io/tree/master/FlatStory",
		})
	],
	shortDesc: `My first attempted browser-based 2d game maker, inspired by the application I first learned to program in: RPG Maker 2000.`,
	desc: () => fromMD(`
There's a soft spot in my heart for 2d games, and *RGP-Maker 2000 was what got me into programming when I was ten*.  A decade and a half later, I decided to look back into it and -- to my dismay -- it wasn't what it used to be at all anymore.  Plus it was only for windows.

*The goal of this project was to recreate that childhood tool*, in browser, and make it free for the world.  I ended up setting this project aside as I simultaneously started my full time job at Coder, and began planning for the PINE Cononagon.
	`),
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "RobotSoul",
	startDate: new Date("Oct 4 2016"),
	endDate: new Date("Jan 10 2017"),
	title: "Robot Soul",
	skills: ["Web App", "Node JS", "HTML5 Canvas", "Synths", "Web Audio API"],
	images: ["robot_soul_loop.gif"],
	// links: () => [
	// ],
	shortDesc: `An in-browser modular-synth inspired DAW (Digital Audio Workstation).`,
	desc: () => fromMD(`
Upset with limitations that no longer exist in modern DAWs (namely, microtonal arrangement), I decided to try my hand at making my own.  Inspired by the DAW "Reason," *this project was doomed from the start*.  No matter how cool the system for arranging and connecting sources was, \`Web Audio API\` is just not meant for this level of processing.
	`),
});


// ------------------------------------------------------------------------------------------------------------------------


CodeEventList.push({
	id: "3dWalkscape",
	startDate: new Date("Oct 8 2016"),
	title: "3d Walkscape",
	shortDesc: "My first time messing with webgl, specifically Three js.  I was attempting to build an explorable scenic cabin in the mountains (as of 2022 I live in one!).",
	skills: ["Three JS", "3d Modeling", "Blender", "Procedural Gen.", "SketchUp", "Games"],
	images: ["3d_walkscape_loop.gif"],
	links: () => [
		externalLink({
			href: "http://sephreed.github.io/walkscape/index.html",
			innards: "Play with live demo",
		}),
		externalLink({
			href: "https://www.youtube.com/watch?v=Cu_6l9h4BIs&t=30s",
			innards: "Watch screencap",
		})
	],
	desc: () => fromMD(` This was a fairly simple project, testing out Three.js to see what it was capable of.  The answer is quite a lot for anything in a browser.  The project has some very rough physics (the collisions are done with raycasting), perlin noise procedurally generated hills (no random seed), and a few interactive elements (the door to the house and the sphere).  I'd like to get back into this project some day.
	`),
});



export default CodeEventList;
