import { div, externalLink } from "helium-ui";
import { ILifeEvent, twoColLayout, extLink, fromMD } from "./LifeEvent";

const MusicEvents: ILifeEvent[] = [];

MusicEvents.push({
  id: "SunAndSnow",
  startDate: new Date("Jan 11 2024"),
  title: "Album - Sun & Snow",
  images: ["music-albums/album-sun-and-snow.jpeg"],
  skills: ["Featured ⭐️", "Synths", "Jazz Theory", "Mix Mastering", "2d Art", "Audio", "3d Modeling", "Blender"],
  shortDesc: "Fourth album, inspired by living in a cabin in the mountains",
  desc: () => [
    fromMD(`
When pandemic hit, I fled for the hills to a remote log cabin near Rocky Mountain National Park.  The property of an old friend.  Shortly after, I met the collaborator of this album.

It took three years of Steel Wolf and I meeting up on weekends to create this album.  Inspired mainly by his jazzy guitar, my jazz theory, some lofi hiphop, and obvious electronic stuff.  It is also the first album to be released on streaming platforms.

The album cover was modeled in Blender and made liberal use of geometry nodes in order to turn the local mountain ranges into a topographical, lit rim, music visualizer.  
`),
    { hackableHTML: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/20mb0gLhnTxW4UJBqbPnTr?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>` }
  ]
});

MusicEvents.push({
  id: "WellConditionedLover",
  startDate: new Date("Sep 11 2014"),
  title: "Album - A Well Conditioned Lover",
  images: ["music-albums/album-a-well-conditioned-lover.jpg"],
  skills: ["Synths", "Jazz Theory", "Mix Mastering", "2d Art", "Audio"],
  shortDesc: "Third album; a collection of more happy and uptempo pieces.",
  links: () => [
    externalLink({
      innards: `Free Stream/Download for "A Well Conditioned Lover"`,
      href: "https://thumbz.bandcamp.com/album/a-well-conditioned-lover",
    }),
  ],
  desc: () =>
    fromMD(`
This was my third album, built of my more happy and poppy tunes.  It takes influence from trance, house, jazz, theater, and video game music.  Most of the songs on this album were produced at the same time as the ones from my second album, but were thematically out of place on that album.  The cover art is meant to represent the paths of the mind (logic/abstraction) and the gut (emotion/deep learning), and the human endeavor to lead them to each other.  This was the kind of heady stuff I was on about when writing most of these songs.
`),
});

MusicEvents.push({
  id: "DawnOfSentience",
  startDate: new Date("Aug 28 2014"),
  title: "Album - The Dawn of Sentience",
  shortDesc: "Second album; a collection of my more down-tempo songs.",
  skills: ["Synths", "Jazz Theory", "Mix Mastering", "2d Art", "Audio"],
  images: ["music-albums/album-the-dawn-of-sentience.jpg"],
  links: () => [
    externalLink({
      innards: `Free Stream/Download for "The Dawn of Sentience"`,
      href: "https://thumbz.bandcamp.com/album/the-dawn-of-sentience",
    }),
  ],
  desc: () =>
    fromMD(`
This was my second album, built of my slower and darker tunes.  It takes influence from early uk dubstep, hip-hop, jazz, theater, and video game music.  This album is dedicated to sentience in humanity, may we one day find ourselves a culture rich with appreciation for all the beautiful wonders of the universe we've been gifted access to.  May we one day live comfortably amongst the stars.
`),
});

MusicEvents.push({
  id: "SourceAlbum",
  startDate: new Date("Aug 28 2014"),
  title: "Album - Source",
  shortDesc:
    "First album; a collection of experimental music, using exclusively from-scratch synthesized sounds.",
  skills: ["Synths", "Jazz Theory", "2d Art", "Audio"],
  images: ["music-albums/album-source.jpg"],
  links: () => [
    externalLink({
      innards: `Free Stream/Download for "Source"`,
      href: "https://thumbz.bandcamp.com/album/source",
    }),
  ],
  desc: () =>
    fromMD(`
Source is a compilation of all the tunes I still had laying around from my introductory days of music making.  It has a very "raw" sound and is all-over-the-place thematically; very experimental.

The core goal of all my music during this time was to not use any pre-made synths, sounds, or recordings.  Pretty much every single sound was made from scratch; hence the "raw" sound.
	`),
});

export default MusicEvents;
