import "./glowingThumbzSymbol.scss";

import { div, addClass } from "helium-ui";



export function renderGlowingThumbzSymbol() {
	return div("GlowingThumbzSymbol", () => {
		const thumbzSymbol = div({
			hackableHTML: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="160 95 150 280">
				<path d="M209.72 302.22L302.22 264.57L236.9 105.63L171.57 264.57L264.06 302.22"></path>
				<path d="M209.72 171.57L302.22 209.43L236.9 366.9L171.57 209.43L264.06 171.57"></path>
			</svg>`,
		}).firstChild;
		let bg1: HTMLElement, bg2: HTMLElement;
		const out = [
			bg2 = addClass("logo-bg-2", thumbzSymbol.cloneNode(true) as any) as any,
			bg1 = addClass("logo-bg-1", thumbzSymbol.cloneNode(true) as any) as any,
			addClass("logo-fg", thumbzSymbol.cloneNode(true) as any),
		];

		// // css animations are every frame and bog things down, this only needs updates every 25+ ms
		// const intervalMs = 250;
		// const loopLength = 25 * 1000;
		// let time = 0;
		// setInterval(() => {
		// 	time += intervalMs;
		// 	time %= loopLength;
		// 	const ratio = time/loopLength;
		// 	const opacity = (ratio < 0.5 ? ratio : (1 - ratio)) * 2;
		// 	bg1.style.opacity = opacity + "";
		// 	bg2.style.opacity = (1-opacity) + "";
		// }, intervalMs)

		return out;
	})
}
