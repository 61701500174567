function HSV_to_RGB(hue, saturation, value) {
	if(hue < 1)
		hue += 1;
	hue %= 1;
	var rgb;
	if(saturation == 0 || value == 0)
		rgb = [value, value, value];  //used car salesman rick jim here!
	else {
		rgb = [];
		var oneThird = 1/3;
		var twoThirds = 2/3;
		var rgbPos = []
		rgbPos[0] = ((hue+oneThird)%1)/twoThirds;  //at 0, red pos = 0.5 or peak of sine wave
		rgbPos[1] = hue/twoThirds;  //at .3333, green pos = 0.5 or peak of sine wave
		rgbPos[2] = (hue-oneThird)/twoThirds;  //at .6666, blue pos = 0.5 or peak of sine wave
		for(var i in rgbPos) {
			var pos = rgbPos[i];
			if(pos > 0 && pos < 1)
				rgb[i] = Math.sin(pos*Math.PI) * value;
			else
				rgb[i] = 0;
		}
		if(saturation < 1) {
			var max = Math.max(Math.max(rgb[0], rgb[1]), rgb[2]);
			for(var i in rgb)
				rgb[i] += (max-rgb[i]) * (1-saturation);
		}
	}
	for(var i in rgb) {
		rgb[i] = Math.floor(rgb[i] * 256)
	}
	return rgb;
}

function setColor(painter, r, g, b, a?) {
	if(a !== undefined){
		painter.fillStyle = painter.strokeStyle = `rgba(${r}, ${g}, ${b}, ${a})`;
	} else {
		painter.fillStyle = painter.strokeStyle = `rgb(${r}, ${g}, ${b})`;
	}
}

export class TangentsAnimation {
	private canvas: HTMLCanvasElement;
	private hue: number;
	private hueForwards: boolean;
	private lineCount: number;
	private painter: CanvasRenderingContext2D;
	private lastPoint: {x: number, y: number};
	private nextPoint: {x: number, y: number}
	// private animating: boolean;
	private fadeCount: number;
	private requestedFrame: number;
	private mouseHeld = false;
	private skipRender = false;

	public constructor(protected args: {
		canvas: HTMLCanvasElement;
		mousemoveTarget?: HTMLElement;
	}) {
		this.canvas = this.args.canvas;
		this.painter = this.canvas.getContext("2d");
		this.hue = 0;
		this.hueForwards = true;
		this.lineCount = 0;
		this.fadeCount = 0;

		// const clearCanvas = () => {
		// 	this.lineCount = -1;
		// 	canvas.classList.remove("--show_instructions");
		// 	this.painter.clearRect(0, 0, this.canvas.width, this.canvas.height);
		// }



		const onMovement = (event: MouseEvent | TouchEvent) => {
			let clientX, clientY;
			if (event instanceof MouseEvent) {
				clientX = event.clientX;
				clientY = event.clientY;
			} else {
				clientX = event.touches[0].clientX;
				clientY = event.touches[0].clientY;
			}
			const bounds = (event.currentTarget as HTMLElement).getBoundingClientRect();

			this.nextPoint = {
				x: clientX - bounds.left,
				y: clientY - bounds.top,
			};

			this.skipRender = false;
			this.drawFrame();
		};

		const movementTarget = this.args.mousemoveTarget || this.canvas;
		movementTarget.addEventListener("mousemove", onMovement);
		movementTarget.addEventListener("touchmove", (e) => {
			e.preventDefault();
			onMovement(e);
		}, { passive: true, capture: false });

		const updateHeld = (held: boolean) => {
			this.mouseHeld = held;
			movementTarget.style.userSelect = held ? "none" : "";
		}
		movementTarget.addEventListener("mousedown", () => updateHeld(true));
		document.addEventListener("mouseup", () => updateHeld(false));
		movementTarget.addEventListener("touchstart", () => updateHeld(true), { passive: true });
		document.addEventListener("touchend", () => updateHeld(false), { passive: true });

		this.updateSize();
		// this.startAnimation();
		this.play();
	}

	public updateSize(attempts = 5) {
		const canvas = this.canvas;
		canvas.height = canvas.offsetHeight;
		canvas.width = canvas.offsetWidth;
		if (canvas.height <= 0|| canvas.width <= 0) {
			if (attempts > 0) {
				requestAnimationFrame(() => this.updateSize(attempts - 1))
				return;
			}
		}
		this.lastPoint = {
			x: canvas.width/2,
			y: canvas.height/2,
		};
	}

	public get playing() {
		return !!this.requestedFrame;
	}

	public play() {
    if (this.playing) { return; }
    this.animate();
  }

  public pause() {
    if (!this.requestedFrame) { return; }
    cancelAnimationFrame(this.requestedFrame);
  }


  protected animate() {
    this.requestedFrame = requestAnimationFrame(() => this.animate())
    this.drawFrame();
  }

	// protected fullFadeTimeout: any;
	private drawFrame() {
		if (this.skipRender) { return; }
		const { canvas, lastPoint, nextPoint, painter } = this;

		if (this.mouseHeld !== true) {
			painter.save();
			painter.globalAlpha = 1;
			painter.globalCompositeOperation = "destination-in";
			const fadeAmount = Math.min(0.9, 0.9 - (0.01 * (this.fadeCount - 10)))
			painter.fillStyle = `rgba(0, 0, 0, ${fadeAmount})`;
			painter.fillRect(0, 0, this.canvas.width, this.canvas.height);
			painter.restore();

			if (this.fadeCount++ == 100) {
				// console.log("STOP");
				// painter.clearRect(0, 0, this.canvas.width, this.canvas.height);
				this.skipRender = true;
			}
		}

		if (this.nextPoint !== undefined) {
			const {x, y} = this.nextPoint;
			this.nextPoint = undefined;
			this.fadeCount = 0;

			// if (this.fullFadeTimeout) {
			// 	clearTimeout(this.fullFadeTimeout);
			// }
			// this.fullFadeTimeout = setTimeout(() => {
			// 	painter.clearRect(0, 0, this.canvas.width, this.canvas.height);
			// 	this.pause();
			// }, 500)

			// if (this.fadeCount++ > 50) {
			// 	painter.save();
			// 	painter.fillStyle = `rgba(255, 0, 0, ${0.99})`;
			// 	painter.globalCompositeOperation = 'destination-in';
			// 	painter.fillRect(0, 0, this.canvas.width, this.canvas.height);
			// 	painter.restore();
			// 	this.fadeCount = 0;
			// }


			var slope = (lastPoint.y - y)/(x- lastPoint.x);
			painter.beginPath();

			let doStrokeLine = false;
			if(Number.isFinite(slope)) {
				var xStart = lastPoint.x - ((canvas.height - lastPoint.y)/slope);
				var xEnd = lastPoint.x + (lastPoint.y/slope);
				painter.moveTo(xStart, canvas.height);
				painter.lineTo(xEnd, 0);
				doStrokeLine = true;

			} else if(x !== lastPoint.x) {
				painter.moveTo(0, y);
				painter.lineTo(canvas.width, y);
				doStrokeLine = true;
			}

			if(doStrokeLine) {
				var rgb = HSV_to_RGB(this.hue, 0.75, 1);
				setColor(painter, rgb[0], rgb[1], rgb[2]);
				painter.globalCompositeOperation = "source-over";
				painter.lineWidth = 3;
				painter.globalAlpha = 0.5;
				painter.stroke();
				lastPoint.x = x;
				lastPoint.y = y;
				this.hue += this.hueForwards ? .005 : -.005;
				if (this.hue > .15) {
					this.hueForwards = false;
				} else if (this.hue < -.6) {
					this.hueForwards = true;
				}
				if (this.lineCount !== -1) {
					this.lineCount++;
				}

				if (this.lineCount === 200) {
					canvas.classList.add("--show_instructions");
				}
			}
		}
	}
}
