import "./pointy.scss";

import { derive, deriveAnchorless, div, noDeps, setInnards, Sourcify } from "helium-ui";
// import { md } from "helium-md";

//(\s+)|([\w']+?[aeiouy]*[^aeiouy\s]?[^\w\s]*)


export class Pointy {

  protected state = Sourcify({
    showPointy: false,
    showBubble: true,
    bubbleFullyShown: false,
    fullText: `Howdy Fucker!  I'm Pointy Mc Pointyface!  I sure hope I didn't surprise you too much, but it looks like you might need some help formatting or something.
    `.replace(/\s+/g, " "),
  })

  constructor() {
    deriveAnchorless(() =>
      this.state.showBubble
      && this.state.fullText
      && this.state.bubbleFullyShown
      && noDeps(() => this.rollInText())
    );
  }

  protected _howdyFuckerAudio: HTMLAudioElement;
  protected get howdyFuckerAudio() {
    return this._howdyFuckerAudio = this._howdyFuckerAudio || new Audio("/assets/audio/Pointy - HowdyFuckers.mp3");
  }

  public show() {
    this.state.showPointy = true;
    setTimeout(() => {
      if (noDeps(() => this.state.showPointy)) {
        this.howdyFuckerAudio.play();
      }
    })
  }
  public hide() { this.state.showPointy = false; }

  protected nodes: {
    rootNode: HTMLDivElement,
    speechBubble: HTMLDivElement,
  }
  public get domNode() {
    if (this.nodes) {
      return this.nodes.rootNode;
    }
    this.nodes = {} as any;
    this.nodes.rootNode = div("PointyContainer", {
      ddxClass: () => [
        this.state.showPointy && "--showPointy",
        this.state.showBubble && "--showBubble",
      ]
    },[
      div("Pointy", {
        onPush: () => this.state.showPointy = false,
        onToucherEnterExit: (ev) => {
          ev.isEnterEvent && this.howdyFuckerAudio.play()
        }
      }),
      div("BubblePositioner", [
        this.nodes.speechBubble = div("SpeechBubble", {
          on: {
            "animationend": (ev) => this.state.bubbleFullyShown = (this.state.showBubble && this.state.showPointy)
          },
          // innards: () => this.state.bubbleText
        }),
      ])
    ]);
    return this.nodes.rootNode;
  }

  protected rollInTextInterval: any;
  protected clearInterval() {
    if (this.rollInTextInterval) {
      clearInterval(this.rollInTextInterval);
      this.rollInTextInterval = undefined;
    }
  }

  protected rollInText() {
    const {fullText} = this.state;

    const innards = new Text(fullText);
    const textContainers: Array<{
      container: ChildNode;
      text: string;
    }> = [];

    const permeate = (checkMe: ChildNode) => {
      if (checkMe.hasChildNodes()) {
        return checkMe.childNodes.forEach(permeate);
      }
      textContainers.push({
        container: checkMe,
        text: checkMe.textContent
      });
      checkMe.textContent = "";
    }
    permeate(innards);

    this.clearInterval();
    setInnards(this.nodes.speechBubble, innards);

    const chunkMatcher = /\s+|\S+/g
    let skipNext = 0;
    let currentContainer = textContainers.shift();
    const rollInNextChunk = () => {
      let nextChunk: string;
      while (!nextChunk) {
        if (!currentContainer) {
          return clearInterval();
        }
        const nextMatch = chunkMatcher.exec(currentContainer.text);
        nextChunk = nextMatch && nextMatch[0];
        if (!nextChunk) {
          currentContainer = textContainers.shift();
        }
      }

      let index = 0;
      this.rollInTextInterval = setInterval(() => {
        if (skipNext > 0) { return skipNext--; }
        const addMe = nextChunk.charAt(index);
        "!?.,".includes(addMe) && (skipNext = 5);

        currentContainer.container.textContent += addMe;
        index++;
        if (index >= nextChunk.length) {
          clearInterval(this.rollInTextInterval);
          this.rollInTextInterval = undefined;
          rollInNextChunk();
        }
      }, 30)
    }
    rollInNextChunk();
  }
}
