import "./topbar.scss";

import { anchor, byId, div } from "helium-ui";
import { APP } from "src/state/AppState";
import { RouteId } from "src/state/RouteState";

export function renderTopbar() {
	const links: Array<{routeId: RouteId, name: string}> = [
		{ routeId: "portfolio",
			name: "Portfolio",
		},
		{ routeId: "resumes",
			name: "Resumes",
		},
		{ routeId: "presence",
			name: "Web Presence",
		},
	];

	return div("Topbar", [
		...links.map((it) => anchor({
			href: () => ({
				path: APP.route.getUrlForRoute(it.routeId),
				hash: null,
				params: { keep: "none" }
			}),
			ddxClass: () => APP.route.id === it.routeId && "--selected",
			innards: it.name,
			onPush: () => byId("Mountains").scrollIntoView({
				block: "nearest",
				behavior: "smooth"
			})
		})),
		anchor("Contact", {
			innards: "Contact",
			href: { params: { set: { showContact: true }}}
		})
	])
}
